import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface ICategorie {
  idCsp: number;
  uuid: string;
  nom: string;
  archive?: boolean;
}

type CategoriesResponse = ICategorie[];

export const categoriesApi = createApi({
  reducerPath: "categories",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Categories"],
  endpoints: (build) => ({
    getCategories: build.query<CategoriesResponse, void>({
      query: () => ({ url: "/csps" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "Categories", uuid } as const)
              ),
              { type: "Categories", id: "LIST" },
            ]
          : [{ type: "Categories", id: "LIST" }],
    }),
    updateCategorie: build.mutation<ICategorie, Partial<ICategorie>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/csps/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Categories", uuid },
      ],
    }),
    addCategorie: build.mutation<ICategorie, Partial<ICategorie>>({
      query: (body) => ({
        url: "/csps",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    deleteCategorie: build.mutation<{ success: boolean; uuid: string }, string>(
      {
        query(uuid) {
          return {
            url: "/csps/" + uuid,
            method: "delete",
          };
        },
        invalidatesTags: (result, error, uuid) => [
          { type: "Categories", uuid },
        ],
      }
    ),
  }),
});

export const {
  useGetCategoriesQuery,
  useUpdateCategorieMutation,
  useAddCategorieMutation,
  useDeleteCategorieMutation,
} = categoriesApi;

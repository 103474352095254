/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { createTheme } from "@mui/material";
import { FONTFACE } from "assets/fonts";

const utilisateur = {
  palette: {
    primary: {
      main: "#08a3a6",
    },
    secondary: {
      main: "#088085",
    },
  },
};

const manager = {
  palette: {
    primary: {
      main: "#3B7DE6",
    },
    secondary: {
      main: "#2462C8",
    },
  },
};

const administrateur = {
  palette: {
    primary: {
      main: "#244C86",
    },
    secondary: {
      main: "#1B3A6A",
    },
  },
};

// Thème du tableau
const Theme = createTheme({
  ...utilisateur,
  typography: {
    fontFamily: "Montserrat, sans-serif",
    subtitle1: {
      fontFamily: "Lato, sans-serif",
    },
    subtitle2: {
      fontFamily: "Lato, sans-serif",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: FONTFACE,
    },
  },
});

export { utilisateur, manager, administrateur };

export default Theme;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { createSlice } from "@reduxjs/toolkit";

enum ThemeRole {
  Utilisateur = "UTILISATEUR",
  Manager = "MANAGER",
  Administrateur = "ADMINISTRATEUR",
}

const themeSlice = createSlice({
  name: "theme",
  initialState: ThemeRole.Utilisateur,
  reducers: {
    setTheme: (state, action) => {
      return (state = action.payload);
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import i18n from "i18n";
import { LANGUES } from "constants/Multilingual";

export default function Traduction() {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string | undefined) => {
    i18n.changeLanguage(lng);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label={t("langue de l'utilisateur")}
        aria-controls="menu-langue"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        size="large"
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        id="menu-langue"
        anchorEl={anchorEl}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {LANGUES.map((langue, index) => (
          <MenuItem
            key={index}
            value={langue.name}
            onClick={() => changeLanguage(langue.name)}
          >
            {langue.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDataProps } from "services/auth";
import decode from "jwt-decode";

export interface AuthState {
  session: Partial<AuthInfo> & {};
  isAuthenticated: boolean | null;
}

type AuthInfo = {
  iat: number;
  exp: number;
  roles: string[];
  login: string;
  uuid: string;
  ip: string;
  societe: string;
  societeServeur: string;
  lang: string;
  connexion: string;
};

const authInfoStringified = localStorage.getItem("ai") || "{}";

const authInfo = JSON.parse(authInfoStringified);
const tokenExpireAt = authInfo.exp || 0;
const isTokenExpired = Number(tokenExpireAt) < Date.now() / 1000;

const authState = {
  session: authInfo,
  isAuthenticated: !!authInfoStringified && !isTokenExpired,
};

const slice = createSlice({
  name: "auth",
  initialState: authState as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { data, token },
      }: PayloadAction<{ data: IDataProps; token: string }>
    ) => {
      state.session = decode(token);
      state.isAuthenticated = true;

      localStorage.setItem("ai", JSON.stringify(state.session));
    },
    logout: (state) => {
      localStorage.removeItem("ai");
      state.session = {};
      state.isAuthenticated = false;
    },
  },
});

export const { setCredentials, logout } = slice.actions;

export default slice.reducer;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import * as Yup from "yup";
import i18n from "../i18n";

Yup.setLocale({
  mixed: {
    default: i18n.t("Veuillez entrer une valeur valide"),
    required: i18n.t("Ce champ est requis"),
    defined: i18n.t("Ce champ doit être défini"),
    oneOf: i18n.t("La valeur du champs ne correspond pas aux choix proposés"),
    notOneOf: i18n.t("Ce champ ne peut pas être égal à cette valeur"),
  },
  string: {
    length: ({ length }) =>
      i18n.t("La valeur doit être exactement de {{length}} caractères", {
        length,
      }),
    min: ({ min }) =>
      i18n.t("La valeur doit être au moins de {{min}} caractères", { min }),
    max: ({ max }) =>
      i18n.t("La valeur doit être au plus de {{max}} caractères", { max }),
    matches: i18n.t("La valeur ne correspond pas au format attendu"),
    email: i18n.t("La valeur doit être une adresse email valide"),
    url: i18n.t("La valeur doit être une URL valide"),
    uuid: i18n.t("La valeur doit être un UUID valide"),
    trim: i18n.t("La valeur ne doit pas commencer ou finir par des espaces"),
    lowercase: i18n.t("La valeur doit être en minuscule"),
    uppercase: i18n.t("La valeur doit être en majuscule"),
  },
  number: {
    min: ({ min }) =>
      i18n.t("La valeur doit être au minimun à {{min}}", { min }),
    max: ({ max }) =>
      i18n.t("La valeur doit être au maximum à {{max}}", { max }),
    lessThan: ({ less }) =>
      i18n.t("La valeur doit être inférieure à {{less}}", { less }),
    moreThan: ({ more }) =>
      i18n.t("La valeur doit être supérieure à {{more}}", { more }),
    positive: i18n.t("La valeur doit être positive"),
    negative: i18n.t("La valeur doit être négative"),
    integer: i18n.t("La valeur doit être un entier"),
  },
  date: {
    min: ({ min }) => i18n.t("La valeur doit être après le {{min}}", { min }),
    max: ({ max }) => i18n.t("La valeur doit être avant le {{max}}", { max }),
  },
  boolean: {
    isValue: ({ value }) => i18n.t("La valeur doit être {{value}}", { value }),
  },
  array: {
    min: ({ min }) =>
      i18n.t("La valeur doit avoir au moins {{min}} éléments", { min }),
    max: ({ max }) =>
      i18n.t("La valeur doit avoir au plus {{max}} éléments", { max }),
    length: ({ length }) =>
      i18n.t("La valeur doit avoir exactement {{length}} éléments", { length }),
  },
});

export default Yup;

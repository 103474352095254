/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useEffect } from "react";
import {
  Route,
  Navigate,
  Routes,
  useParams,
  useRoutes,
  useNavigate,
} from "react-router-dom";

import Utilisateur from "./Utilisateur";
import Manager from "./Manager";
import Administrateur from "./Administrateur";
import { useAuth } from "hooks/useAuth";
import { useAppDispatch } from "store";
import { setTheme } from "assets/themes/themeSlice";

const PREFIX_UTILISATEUR = "u";
const PREFIX_MANAGER = "m";
const PREFIX_ADMINISTRATEUR = "a";

export default function DashboardRoute(): JSX.Element {
  let navigate = useNavigate();
  let { societe } = useParams();
  const auth = useAuth();

  // Si le paramètre de société dans l'url est différent de la société
  // pour laquelle l'utilisateur est authentifié,
  // alors on redirige vers la page précédente.
  useEffect(() => {
    let authSociety = auth.session.societe?.replace("societe_", "");
    authSociety !== societe && navigate("../" + (authSociety || "/"));
  }, [auth.session.societe, navigate, societe]);

  return (
    <Routes>
      <Route path=":prefix/*" element={<DashboardRouter />} />
      <Route
        path="/"
        element={
          <Navigate
            to={
              (auth.session.roles?.includes("ROLE_ADMINISTRATEUR")
                ? PREFIX_ADMINISTRATEUR
                : auth.session.roles?.includes("ROLE_MANAGER")
                ? PREFIX_MANAGER
                : PREFIX_UTILISATEUR) + "/dashboard"
            }
          />
        }
      />
    </Routes>
  );
}

const DashboardRouter = () => {
  let { prefix } = useParams();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Si le paramètre du préfixe est différent des préfixes u, m ou a ou qu'il n'existe pas,
  // alors on redirige vers la page précédente.
  useEffect(() => {
    ![PREFIX_UTILISATEUR, PREFIX_MANAGER, PREFIX_ADMINISTRATEUR].includes(
      prefix || ""
    ) && navigate("/");
  }, [navigate, prefix]);

  // Modifie le theme selon le préfixe
  useEffect(() => {
    prefix === PREFIX_UTILISATEUR && dispatch(setTheme("UTILISATEUR"));
    prefix === PREFIX_MANAGER && dispatch(setTheme("MANAGER"));
    prefix === PREFIX_ADMINISTRATEUR && dispatch(setTheme("ADMINISTRATEUR"));
  }, [dispatch, prefix]);

  // On récupère les routes de chaque roles
  const { routes: utilisateurRoutes } = Utilisateur();
  const { routes: managerRoutes } = Manager();
  const { routes: administrateurRoutes } = Administrateur();

  let utilisateur = useRoutes(utilisateurRoutes);
  let manager = useRoutes(managerRoutes);
  let administrateur = useRoutes(administrateurRoutes);

  return (
    <>
      {prefix === PREFIX_UTILISATEUR && <>{utilisateur}</>}
      {prefix === PREFIX_MANAGER && <>{manager}</>}
      {prefix === PREFIX_ADMINISTRATEUR && <>{administrateur}</>}
    </>
  );
};

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import LoginPage from "features/auth/LoginPage";
import RequestPasswordPage from "features/auth/RequestPasswordPage";
import ResetPasswordPage from "features/auth/ResetPasswordPage";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

export default function AuthRoute() {
  return (
    <>
      <Routes>
        <Route index element={<LoginPage />} />
        <Route path="request-password" element={<RequestPasswordPage />} />
        <Route path="reset-password/:token" element={<ResetPasswordPage />} />
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
      <Outlet />
    </>
  );
}

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

/**
 * Page 404
 *
 * @license Proprietary
 * @copyright e2Time.com
 */
export default function Erreur404Page() {
  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        height: "100vh",
        background:
          "linear-gradient(to right bottom, " +
          theme.palette.primary.main +
          ", " +
          theme.palette.secondary.main +
          ")",
      })}
    >
      <Container
        component="section"
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Typography
          sx={(theme) => ({
            color: "white",
            position: "relative",
            top: "50vh",
            transform: "translateY(-50%)",
            [theme.breakpoints.up("md")]: {
              fontSize: "12rem",
            },
          })}
          variant="h1"
        >
          404
        </Typography>
        <Container
          sx={(theme) => ({
            padding: theme.spacing(3, 2),
            marginTop: "auto",
          })}
          component="footer"
          maxWidth="sm"
        >
          <Typography variant="body1">
            <Box
              component={Link}
              to="/"
              sx={{
                color: "white",
              }}
            >
              {t("Retour à la page d'accueil")}
            </Box>
          </Typography>
        </Container>
      </Container>
    </Box>
  );
}

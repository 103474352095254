/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Utilisateur(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <path
        d="M15,9.697c-1.849,0-5.303,0.866-5.303,2.951v4.233c0,1.093,0.841,2.095,1.881,2.388v6.548h1.2V13.589
				h-1.2v4.387c-0.399-0.247-0.681-0.698-0.681-1.095v-4.233c0-0.967,2.483-1.751,4.103-1.751s4.103,0.784,4.103,1.751v4.233
				c0,0.396-0.282,0.848-0.681,1.095v-4.387h-1.2v12.229h1.2v-6.548c1.04-0.292,1.881-1.294,1.881-2.388v-4.233
				C20.303,10.563,16.849,9.697,15,9.697z"
      />
      <path
        d="M21.114,11.108c-0.163,0-0.34,0.009-0.526,0.026l0.112,1.194c0.146-0.014,0.286-0.021,0.414-0.021
				c1.435,0,3.633,0.784,3.633,1.751v4.233c0,0.362-0.315,0.594-0.681,0.713V15h-1.2v10.817h1.2v-5.58
				c0.992-0.194,1.881-0.857,1.881-1.946v-4.233C25.947,11.999,22.747,11.108,21.114,11.108z"
      />
      <path
        d="M15,9.486c1.438,0,2.481-1.043,2.481-2.481V6.064c0-1.438-1.043-2.481-2.481-2.481
				s-2.481,1.043-2.481,2.481v0.941C12.519,8.442,13.562,9.486,15,9.486z M13.719,6.064c0-0.778,0.503-1.281,1.281-1.281
				s1.281,0.503,1.281,1.281v0.941c0,0.778-0.503,1.281-1.281,1.281s-1.281-0.502-1.281-1.281V6.064z"
      />
      <rect x="14.4" y="18.292" width="1.2" height="7.525" />
      <path
        d="M21.114,10.897c1.438,0,2.481-1.043,2.481-2.481V7.475c0-1.438-1.043-2.481-2.481-2.481
				c-1.438,0-2.481,1.043-2.481,2.481v0.941C18.633,9.854,19.676,10.897,21.114,10.897z M19.833,7.475
				c0-0.778,0.503-1.281,1.281-1.281s1.281,0.502,1.281,1.281v0.941c0,0.778-0.502,1.281-1.281,1.281s-1.281-0.503-1.281-1.281
				V7.475z"
      />
      <path
        d="M4.053,14.059v4.233c0,1.088,0.889,1.752,1.881,1.946v5.58h1.2V15h-1.2v4.005
				c-0.366-0.119-0.681-0.351-0.681-0.713v-4.233c0-0.967,2.198-1.751,3.633-1.751c0.128,0,0.268,0.007,0.414,0.021l0.112-1.194
				c-0.187-0.018-0.363-0.026-0.526-0.026C7.253,11.108,4.053,11.999,4.053,14.059z"
      />
      <path
        d="M8.886,10.897c1.438,0,2.481-1.043,2.481-2.481V7.475c0-1.438-1.043-2.481-2.481-2.481
				S6.404,6.037,6.404,7.475v0.941C6.404,9.854,7.448,10.897,8.886,10.897z M7.604,7.475c0-0.778,0.503-1.281,1.282-1.281
				c0.778,0,1.281,0.502,1.281,1.281v0.941c0,0.778-0.503,1.281-1.281,1.281c-0.779,0-1.282-0.503-1.282-1.281V7.475z"
      />
    </SvgIcon>
  );
}

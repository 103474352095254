/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useTranslation } from "react-i18next";
import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import logo from "assets/images/logo-e2time.png";
import AuthRoute from "routes/AuthRoute";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

// nombre aléatoire entre 1 et 5
const random = Math.floor(Math.random() * 5) + 1;

export default function AuthPage() {
  // Initialisation de la traduction
  const { t } = useTranslation();

  return (
    <Grid component="main" container sx={{ height: "100vh" }}>
      <Grid component="aside" item xs>
        <Box
          component="iframe"
          id="landing"
          title="Landing Page"
          src={"https://landing.e2time.com/" + random + ".html"}
          sx={{
            width: "100%",
            height: "100%",
            border: "none",
            display: { xs: "none", md: "block" },
          }}
        />
      </Grid>
      <Grid
        component="article"
        container
        item
        xs={12}
        md={6}
        lg={4}
        alignItems="flex-end"
        sx={{ pl: 5, pr: { xs: 5, md: 10 } }}
      >
        <Grid component="section" item xs={12}>
          <Box
            component="img"
            src={logo}
            alt="Logo E2time"
            sx={{ width: "100%", maxWidth: "160px" }}
          />
          <AuthRoute />
        </Grid>
        <Grid component="footer" item xs={12}>
          <Divider sx={{ borderColor: "primary.main" }} />
          <Typography variant="body2" component="p" sx={{ my: 2 }}>
            <Link
              href="https://www.e2time.com/"
              underline="none"
              rel="noreferrer"
              target="_blank"
              sx={{ color: "primary.main", textTransform: "uppercase" }}
            >
              e2time.com
            </Link>{" "}
            | {t("Solution de gestion des temps et planification")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ParametrageModule(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <rect
        x="4.171"
        y="5.401"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        width="21.658"
        height="19.197"
      />

      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="5.155"
        y1="8.355"
        x2="24.845"
        y2="8.355"
      />

      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="5.648"
        y1="6.879"
        x2="6.632"
        y2="6.879"
      />

      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="7.124"
        y1="6.879"
        x2="8.108"
        y2="6.879"
      />

      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="8.601"
        y1="6.879"
        x2="9.585"
        y2="6.879"
      />
      <circle
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="9.585"
        cy="14.262"
        r="1.477"
      />

      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="9.585"
        y1="10.324"
        x2="9.585"
        y2="12.785"
      />

      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="9.585"
        y1="15.738"
        x2="9.585"
        y2="23.121"
      />
      <circle
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="15"
        cy="19.186"
        r="1.477"
      />
      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="15"
        y1="10.324"
        x2="15"
        y2="17.708"
      />
      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="15"
        y1="20.66"
        x2="15"
        y2="23.121"
      />
      <circle
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        cx="20.415"
        cy="16.23"
        r="1.476"
      />

      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="20.414"
        y1="10.324"
        x2="20.414"
        y2="14.754"
      />

      <line
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        x1="20.414"
        y1="17.708"
        x2="20.414"
        y2="23.121"
      />
    </SvgIcon>
  );
}

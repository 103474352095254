/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Planning(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <path
        d="M26.64,5.315h-3.407v1.2l3.375-0.032l0.005,3.375H3.365L3.359,6.515h3.407v-1.2H3.359
		c-0.644,0-1.167,0.524-1.167,1.168v19.306c0,0.644,0.524,1.168,1.167,1.168H26.64c0.644,0,1.168-0.524,1.168-1.168V6.483
		C27.808,5.839,27.284,5.315,26.64,5.315z M3.392,25.789l-0.025-14.73h23.249l0.024,14.699L3.392,25.789z"
      />
      <rect x="21.497" y="3.643" width="1.2" height="3.975" />
      <rect x="9.037" y="5.315" width="11.925" height="1.2" />
      <rect x="7.302" y="3.643" width="1.2" height="3.975" />
      <rect x="11.309" y="14.4" width="1.703" height="1.2" />
      <rect x="14.149" y="14.4" width="1.702" height="1.2" />
      <rect x="16.987" y="14.4" width="1.703" height="1.2" />
      <rect x="19.827" y="14.4" width="1.704" height="1.2" />
      <rect x="8.47" y="17.239" width="1.704" height="1.2" />
      <rect x="11.309" y="17.239" width="1.703" height="1.2" />
      <rect x="14.149" y="17.239" width="1.702" height="1.2" />
      <rect x="16.987" y="17.239" width="1.703" height="1.2" />
      <rect x="19.827" y="17.239" width="1.704" height="1.2" />
      <rect x="8.47" y="20.078" width="1.704" height="1.2" />
      <rect x="11.309" y="20.078" width="1.703" height="1.2" />
      <rect x="14.149" y="20.078" width="1.702" height="1.2" />
      <rect x="16.987" y="20.078" width="1.703" height="1.2" />
    </SvgIcon>
  );
}

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";
import { IEtiquette } from "./etiquettes";

export enum TypePausePeriode {
  OUI = "Oui",
  NON_REEL = "Non reel",
  NON_FIXE = "Non fixe",
  NON_AUTO = "Non auto",
}

export type PeriodeProps = {
  idJourneeTypePeriode: number;
  journeeTypeId: number;
  nbPeriode: number;
  heureDebut: string;
  heureFin: string;
  pause: boolean;
  typePausePeriode: TypePausePeriode;
  pauseDuree: string;
  pauseMax: string;
  toleranceDebut: number;
  toleranceFin: number;
};

type CongeProps = {
  journeeTypeId: number;
  congeMatinDebutAM: string;
  congeMatinFinAM: string;
  congeMatinDebutPM: string;
  congeMatinFinPM: string;
  travailMatinTotal: string;
  congeMatinTotal: string;
  congeApremDebutAM: string;
  congeApremFinAM: string;
  congeApremDebutPM: string;
  congeApremFinPM: string;
  congeApremTotal: string;
  travailApremTotal: string;
  congeJourneeDebutAM: string;
  congeJourneeFinAM: string;
  congeJourneeDebutPM: string;
  congeJourneeFinPM: string;
  congeJourneeTotal: string;
};

export enum TypePeriode {
  FIXE = "Fixe",
  VARIABLE = "Variable",
  REEL = "Reel",
}

export enum TypePointage {
  NORMAL = "Normal",
  AUTOMATIQUE = "Pointages automatiques",
  UNIQUE = "1 seul pointage",
  UNIQUE_PAR_PERIODE = "1 seul pointage par période",
  REPAS_DOUBLE = "Pointage repas double",
}

export enum JourneeAssimilee {
  JOURNEE = "Journée",
  MATIN = "Matin",
  APRES_MIDI = "Après-midi",
  SOIR = "Soir",
  NUIT = "Nuit",
  TAMPON = "Tampon",
}

export enum TypePause {
  OBLIGATOIRE = "Obligatoire",
  REEL = "Réel",
  INTERDIT = "Interdit",
}

export interface IJourneeType {
  idJourneeType: number;
  uuid: string;
  primaire: boolean;
  idJourneeTypePrimaire: number;
  archive: boolean;
  idCreateur: number;
  libelle: string;
  abreviation: string;
  nbPeriode: number;
  codeCouleur: string;
  typePeriode: TypePeriode;
  etiquettes: IEtiquette[];
  typePointage: TypePointage;
  primaireDefaut: boolean;
  toleranceDebut: boolean;
  toleranceFin: boolean;
  interditDebut: boolean;
  entreeMin: string;
  sortieMax: string;
  debutGraph: string;
  finGraph: string;
  dureeJourMin: string;
  dureeJourMax: string;
  dureeJourTheorique: string;
  dureeJourTheorique2: string;
  journeeAssimilee: JourneeAssimilee;
  equivalentEnJours: number;
  toleranceDebutRepas: number;
  toleranceFinRepas: number;
  dureePauseRepas: string;
  typePause: TypePause;
  anomalie: boolean;
  anomalieMin: string;
  anomalieMax: string;
  pauseRepasPayee: boolean;
  penalitePauseRepas: boolean;
  penalitePauseRepasDetail: string;
  afficherJourneeType: boolean;
  repartitionJour: boolean;
  repartitionJour1: string;
  repartitionJour2: string;
  periodes: PeriodeProps[];
  conges: CongeProps;
}

type JourneesTypesResponse = IJourneeType[];

export const journeesTypesApi = createApi({
  reducerPath: "journeestypes",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["JourneesTypes"],
  endpoints: (build) => ({
    getJourneesTypes: build.query<JourneesTypesResponse, void>({
      query: () => ({ url: "/journees-types" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "JourneesTypes", uuid } as const)
              ),
              { type: "JourneesTypes", id: "LIST" },
            ]
          : [{ type: "JourneesTypes", id: "LIST" }],
    }),
    getJourneeType: build.query<IJourneeType, string>({
      query: (uuid) => ({ url: "/journees-types/" + uuid }),
      providesTags: (result, error, uuid) => [{ type: "JourneesTypes", uuid }],
    }),
    getJourneeTypeDefaut: build.query<IJourneeType, string>({
      query: () => ({ url: "/journees-types/defaut" }),
      providesTags: ["JourneesTypes"],
    }),
    updateJourneeType: build.mutation<IJourneeType, Partial<IJourneeType>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/journees-types/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "JourneesTypes", uuid },
      ],
    }),
    updateJourneeTypeDefaut: build.mutation<
      IJourneeType,
      Partial<IJourneeType>
    >({
      query(uuid) {
        return {
          url: "/journees-types/" + uuid + "/defaut",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "JourneesTypes", uuid },
      ],
    }),
    addJourneeType: build.mutation<IJourneeType, Partial<IJourneeType>>({
      query: (body) => ({
        url: "/journees-types",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "JourneesTypes", id: "LIST" }],
    }),
    archiveJourneeType: build.mutation<IJourneeType, string | number>({
      query(uuid) {
        return {
          url: "/journees-types/" + uuid + "/archives",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, uuid) => [
        { type: "JourneesTypes", uuid },
      ],
    }),
  }),
});

export const {
  useGetJourneesTypesQuery,
  useGetJourneeTypeQuery,
  useGetJourneeTypeDefautQuery,
  useUpdateJourneeTypeMutation,
  useUpdateJourneeTypeDefautMutation,
  useAddJourneeTypeMutation,
  useArchiveJourneeTypeMutation,
} = journeesTypesApi;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Message(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <path
        d="M25.241,6.463H4.759c-0.896,0-1.625,0.729-1.625,1.624v13.826c0,0.896,0.729,1.624,1.625,1.624h20.482
	c0.896,0,1.625-0.729,1.625-1.624V8.087C26.866,7.191,26.137,6.463,25.241,6.463z M17.11,15.37
	c-1.164,1.164-3.058,1.163-4.219,0.001L5.234,7.663h19.61L17.11,15.37z M10.816,14.985l-6.481,6.481V8.46L10.816,14.985z
	 M11.662,15.836l0.379,0.382c0.816,0.815,1.888,1.223,2.959,1.223c1.071,0,2.143-0.407,2.958-1.223l0.286-0.285l6.599,6.403H5.162
	L11.662,15.836z M19.094,15.087l6.572-6.549v12.925L19.094,15.087z"
      />
    </SvgIcon>
  );
}

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useMatomo } from "@datapunt/matomo-tracker-react";
import AuthPage from "features/auth/AuthPage";
import DashboardPage from "features/dashboard/DashboardPage";
import Erreur404Page from "features/erreur/Erreur404Page";
import { useAuth } from "hooks/useAuth";
import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ErreurSocietePage from "../features/erreur/ErreurSocietePage";

/**
 * Point d'entrée du routage principal
 * URL de base: "front.e2time.local"
 */
export default function MainRoutes(): JSX.Element {
  const auth = useAuth();
  let location = useLocation();
  const { trackPageView } = useMatomo();

  /**
   * Permet de tracker les pages du site
   * @see {@link https://github.com/Amsterdam/matomo-tracker/tree/master/packages/react | Matomo Tracker} */
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      let state = location.state as { title: string };
      trackPageView({
        documentTitle: state?.title ? state.title : "No title page",
        href: location.pathname,
      });
    }
  }, [location, trackPageView]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          auth.isAuthenticated && auth.session.societe ? (
            <Navigate to={auth.session.societe.replace("societe_", "")} />
          ) : (
            <ErreurSocietePage />
          )
        }
      />
      <Route
        path="/:societe/*"
        element={auth.isAuthenticated ? <DashboardPage /> : <AuthPage />}
      />
      <Route path="*" element={<Erreur404Page />} />
    </Routes>
  );
}

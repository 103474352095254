/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import Montserrat from "assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf";
import MontserratItalic from "assets/fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf";

import LatoBlack from "assets/fonts/Lato/Lato-Black.ttf";
import LatoBlackItalic from "assets/fonts/Lato/Lato-BlackItalic.ttf";
import LatoBold from "assets/fonts/Lato/Lato-Bold.ttf";
import LatoBoldItalic from "assets/fonts/Lato/Lato-BoldItalic.ttf";
import LatoItalic from "assets/fonts/Lato/Lato-Italic.ttf";
import LatoLight from "assets/fonts/Lato/Lato-Light.ttf";
import LatoLightItalic from "assets/fonts/Lato/Lato-LightItalic.ttf";
import LatoRegular from "assets/fonts/Lato/Lato-Regular.ttf";
import LatoThin from "assets/fonts/Lato/Lato-Thin.ttf";
import LatoThinItalic from "assets/fonts/Lato/Lato-ThinItalic.ttf";

export const FONTFACE = `
  @font-face {
    font-family: 'Montserrat';
    src: url(${Montserrat}) format('truetype-variations');
    font-weight: 1 999;
    font-style: normal;
  }
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratItalic}) format('truetype-variations');
    font-weight: 1 999;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoBlackItalic}) format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoBoldItalic}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoItalic}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoThin}) format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoThinItalic}) format('truetype');
    font-weight: 100;
    font-style: italic;
  }
`;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import DashboardRoute from "routes/DashboardRoute";
import DashboardAppBar from "./DashboardAppBar";
import DashboardDrawer from "./DashboardDrawer";
import StyledToolbar from "./StyledToolbar";
import logo from "assets/images/logo-e2time.png";

export default function Dashboard() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <DashboardAppBar />
      <Box component="nav">
        <DashboardDrawer />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          width: 0, //hack pour empêcher les tableaux de sortir de l'écran
        }}
      >
        <Box component="section" sx={{ p: 4, flexGrow: 1 }}>
          <StyledToolbar />
          <DashboardRoute />
        </Box>
        <Box
          component="footer"
          sx={(theme) => ({
            textAlign: "right",
            borderTop: "1px solid " + theme.palette.divider,
            px: 4,
            py: 2,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          })}
        >
          <Box
            component="img"
            src={logo}
            alt="logo"
            sx={{ height: "30px", paddingRight: 1 }}
          />
          <Typography component="p" variant="body1">
            {t("Solution de gestion des temps et planification")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

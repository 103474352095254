import { useState } from "react";

type Value = {
  handleChange?: any;
  handleSubmit?: any;
  values?: any;
  setValues?: any;
};

const useForm = (callback: any, initialValues: object = {}) => {
  const [values, setValues] = useState(initialValues);

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    //console.log(event);
    //console.log(values);
    callback();
  };

  const handleChange = (
    event: React.ChangeEvent<{ name: string; value: any }>
  ) => {
    // Récupérer le texte et pas la value
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
  } as Value;
};

export default useForm;

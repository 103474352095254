import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from ".";

export interface IUtilisateur {
  idUtilisateur: number;
  uuid: string;
  login: string;
  nom: string;
  prenom: string;
  email: string;
  email2: string;
  roles: string;
  archive: boolean;
  compteBloque: boolean;
  cspId: number;
}

type UtilisateursResponse = IUtilisateur[];

export const utilisateursApi = createApi({
  reducerPath: "utilisateurs",
  baseQuery: baseQuery,
  tagTypes: ["Utilisateurs"],
  endpoints: (build) => ({
    getUtilisateurs: build.query<UtilisateursResponse, void>({
      query: () => ({ url: "/utilisateurs" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "Utilisateurs", uuid } as const)
              ),
              { type: "Utilisateurs", id: "LIST" },
            ]
          : [{ type: "Utilisateurs", id: "LIST" }],
    }),
    getUtilisateur: build.query<IUtilisateur, string | undefined>({
      query: (uuid) => ({ url: "/utilisateurs/" + uuid }),
      providesTags: (result, error, uuid) => [{ type: "Utilisateurs", uuid }],
    }),
    updateUtilisateur: build.mutation<IUtilisateur, Partial<IUtilisateur>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/utilisateurs/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Utilisateurs", uuid },
      ],
    }),
    addUtilisateur: build.mutation<IUtilisateur, Partial<IUtilisateur>>({
      query: (body) => ({
        url: "/utilisateurs",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Utilisateurs", id: "LIST" }],
    }),
    deleteUtilisateur: build.mutation<
      { success: boolean; uuid: string },
      string
    >({
      query(uuid) {
        return {
          url: "/utilisateurs/" + uuid,
          method: "delete",
        };
      },
      invalidatesTags: (result, error, uuid) => [
        { type: "Utilisateurs", uuid },
      ],
    }),
  }),
});

export const {
  useGetUtilisateursQuery,
  useGetUtilisateurQuery,
  useUpdateUtilisateurMutation,
  useAddUtilisateurMutation,
  useDeleteUtilisateurMutation,
} = utilisateursApi;

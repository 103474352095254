/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useGetUtilisateurQuery } from "services/utilisateurs";
import { useAuth } from "hooks/useAuth";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";

import { MINI_DRAWER_WIDTH } from "./DashboardDrawer";
import { useDashboard } from "hooks/useDashboard";

export default function Profil() {
  const { t } = useTranslation();
  const auth = useAuth();

  const dashboard = useDashboard();

  const { data: utilisateur } = useGetUtilisateurQuery(auth.session.uuid);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{
        height: "150px",
      }}
    >
      {utilisateur && (
        <>
          <Avatar
            alt={utilisateur.prenom + " " + utilisateur.nom}
            src="https://avatarfiles.alphacoders.com/258/thumb-258594.png"
            sx={(theme) => ({
              width: dashboard.openMenu ? 60 : 40,
              height: dashboard.openMenu ? 60 : 40,
              left: !dashboard.openMenu ? "-" + MINI_DRAWER_WIDTH + "px" : 0,
              transition: theme.transitions.duration.standard + "ms",
            })}
          />
          <Box sx={{ display: "flex" }}>
            <Fade in={dashboard.openMenu} appear={false}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {utilisateur.prenom + " " + utilisateur.nom}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  sx={{ textTransform: "uppercase" }}
                >
                  {auth.session.roles?.includes("ROLE_ADMINISTRATEUR")
                    ? t("Administrateur")
                    : auth.session.roles?.includes("ROLE_MANAGER")
                    ? t("Manager")
                    : t("Utilisateur")}
                </Typography>
              </Box>
            </Fade>
          </Box>
        </>
      )}
    </Stack>
  );
}

import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import FR from "./assets/locales/fr/translations.json";
import EN from "./assets/locales/en/translations.json";
import ES from "./assets/locales/es/translations.json";
import IT from "./assets/locales/it/translations.json";
import DE from "./assets/locales/de/translations.json";
import NL from "./assets/locales/nl/translations.json";
import PT from "./assets/locales/pt/translations.json";

export const defaultNS = "translations";

export const resources = {
  fr: {
    translations: FR,
  },
  en: {
    translations: EN,
  },
  es: {
    translations: ES,
  },
  it: {
    translations: IT,
  },
  de: {
    translations: DE,
  },
  nl: {
    translations: NL,
  },
  pt: {
    translations: PT,
  },
} as const;

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    lng: "fr",
    fallbackLng: "fr", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources,
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS,
  });

export default i18n;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";

import useForm from "../../hooks/useForm";

/**
 * Page Erreur Société
 *
 * @license Proprietary
 * @copyright e2Time.com
 */
export default function ErreurSocietePage() {
  let navigate = useNavigate();

  const callback = () => {
    navigate("/" + values.societe);
  };

  const { values, handleChange, handleSubmit } = useForm(callback);

  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        height: "100vh",
        background:
          "linear-gradient(to right bottom, " +
          theme.palette.primary.main +
          ", " +
          theme.palette.secondary.main +
          ")",
      })}
    >
      <Container
        component="main"
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            position: "relative",
            top: "50vh",
            transform: "translateY(-50%)",
          }}
        >
          <Paper
            sx={(theme) => ({
              padding: theme.spacing(3, 2),
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            })}
          >
            <Avatar
              sx={{
                margin: 1,
                backgroundColor: "secondary.main",
              }}
            >
              <SearchIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("Oups, vous êtes perdu")} ?
            </Typography>
            <Box
              component="form"
              sx={{
                width: "100%", // Fix IE 11 issue.
                marginTop: 1,
              }}
              onSubmit={handleSubmit}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="societe"
                label={t("Indiquer le nom de votre société")}
                name="societe"
                autoComplete="societe"
                autoFocus
                value={values.societe || ""}
                onChange={handleChange}
              />

              <Box sx={{ position: "relative" }}>
                <Button
                  type="submit"
                  sx={(theme) => ({
                    margin: theme.spacing(3, 0, 2),
                  })}
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  {t("Redirection")}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
}

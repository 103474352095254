import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IModeleMajoration {
  idMajoration: number;
  uuid: string;
  nom: string;
  archive: false;
}

type ModelesMajorationsResponse = IModeleMajoration[];

export const modelesMajorationsApi = createApi({
  reducerPath: "modelesmajorations",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["ModelesMajorations"],
  endpoints: (build) => ({
    getModelesMajorations: build.query<ModelesMajorationsResponse, void>({
      query: () => ({ url: "/modeles-majorations" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "ModelesMajorations", uuid } as const)
              ),
              { type: "ModelesMajorations", id: "LIST" },
            ]
          : [{ type: "ModelesMajorations", id: "LIST" }],
    }),
    getModeleMajoration: build.query<IModeleMajoration, string>({
      query: (uuid) => ({ url: "/modeles-majorations/" + uuid }),
      providesTags: (result, error, uuid) => [
        { type: "ModelesMajorations", uuid },
      ],
    }),
    updateModeleMajoration: build.mutation<
      IModeleMajoration,
      Partial<IModeleMajoration>
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/modeles-majorations/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "ModelesMajorations", uuid },
      ],
    }),
    addModeleMajoration: build.mutation<
      IModeleMajoration,
      Partial<IModeleMajoration>
    >({
      query: (body) => ({
        url: "/modeles-majorations",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "ModelesMajorations", id: "LIST" }],
    }),
    archiveModeleMajoration: build.mutation<IModeleMajoration, string | number>(
      {
        query(uuid) {
          return {
            url: "/modeles-majorations/" + uuid + "/archives",
            method: "patch",
          };
        },
        invalidatesTags: (result, error, uuid) => [
          { type: "ModelesMajorations", uuid },
        ],
      }
    ),
  }),
});

export const {
  useGetModelesMajorationsQuery,
  useGetModeleMajorationQuery,
  useUpdateModeleMajorationMutation,
  useAddModeleMajorationMutation,
  useArchiveModeleMajorationMutation,
} = modelesMajorationsApi;

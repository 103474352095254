/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Yup from "constants/Yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Stack, Box, Button, Typography } from "@mui/material";

import {
  useRequestPasswordMutation,
  IRequestPasswordForm,
} from "services/auth";
import { TextField } from "features/common/reacthookform/Controller";

/**
 * @see https://github.com/jquense/yup
 */
const validationSchema = Yup.object({
  email: Yup.string().required(),
  login: Yup.string().required(),
  societe: Yup.string().required(),
});

/**
 * Formulaire d'authentification
 */
function RequestPasswordForm() {
  // Initialisation de la traduction
  const { t } = useTranslation();

  let navigate = useNavigate();

  let { societe } = useParams();

  const [requestPassword] = useRequestPasswordMutation();
  /**
   * @see https://react-hook-form.com/api/useform
   */
  const defaultValues = {
    email: "",
    login: "",
    societe: "societe_" + societe,
  };

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<IRequestPasswordForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: IRequestPasswordForm) => {
    requestPassword(data)
      .unwrap()
      .then(() => navigate("/"))
      .catch(({ data }) => {
        const { children: errors } = data.errors;
        Object.entries(errors).forEach(([name, { errors }]: any[]) => {
          errors &&
            setError(name, {
              message: errors[0],
            });
        });
      });
  };

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        id="login"
        name="login"
        label={t("Identifiant")}
        error={!!errors.login}
        helperText={!!errors.login && errors.login.message}
      />
      <TextField
        control={control}
        fullWidth
        id="email"
        name="email"
        variant="outlined"
        label={t("E-mail")}
        error={!!errors.email}
        helperText={!!errors.email && errors.email.message}
      />
      <Box
        component={Link}
        to="/"
        sx={{
          textDecoration: "none",
          textAlign: "right",
          color: "primary.main",
        }}
      >
        <Typography variant="body2">{t("> Retour à l'accueil")}</Typography>
      </Box>
      <Box>
        <Button color="primary" variant="contained" type="submit">
          {t("Envoyer ma demande")}
        </Button>
      </Box>
    </Stack>
  );
}

export default RequestPasswordForm;

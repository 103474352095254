import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IEtiquette {
  uuid?: string;
  libelle: string;
  type: string;
}

type EtiquettesResponse = IEtiquette[];

export const etiquettesApi = createApi({
  reducerPath: "etiquettes",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Etiquettes"],
  endpoints: (build) => ({
    getEtiquettesJourneeType: build.query<EtiquettesResponse, void>({
      query: () => ({ url: "/journees-types-etiquettes" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "Etiquettes", uuid } as const)
              ),
              { type: "Etiquettes", id: "LIST" },
            ]
          : [{ type: "Etiquettes", id: "LIST" }],
    }),
    updateEtiquette: build.mutation<IEtiquette, Partial<IEtiquette>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/etiquettes/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Etiquettes", uuid },
      ],
    }),
    addEtiquette: build.mutation<IEtiquette, Partial<IEtiquette>>({
      query: (body) => ({
        url: "/etiquettes",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Etiquettes", id: "LIST" }],
    }),
    deleteEtiquette: build.mutation<{ success: boolean; uuid: string }, string>(
      {
        query(uuid) {
          return {
            url: "/etiquettes/" + uuid,
            method: "delete",
          };
        },
        invalidatesTags: (result, error, uuid) => [
          { type: "Etiquettes", uuid },
        ],
      }
    ),
  }),
});

export const {
  useGetEtiquettesJourneeTypeQuery,
  useUpdateEtiquetteMutation,
  useAddEtiquetteMutation,
  useDeleteEtiquetteMutation,
} = etiquettesApi;

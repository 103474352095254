import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from "..";

export interface ITraduction {
  [x: string]: any;
  idTraduction: number | string;
  table: string;
  uuid: string;
  champ: string;
  valeur: string;
  lang: string;
  entityId: number;
  externalId: number | null;
}

export const traductionsApi = createApi({
  reducerPath: "traductions",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Traductions"],
  endpoints: () => ({}),
});

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Alerte(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <path
        d="M14.985,26.653c-1.045,0-1.929-0.629-1.929-1.373h-1.7c0,1.694,1.628,3.072,3.629,3.072
				s3.628-1.378,3.628-3.072h-1.699C16.914,26.024,16.03,26.653,14.985,26.653z"
      />
      <path
        d="M24.724,21.125c-0.537-0.306-1.435-0.816-1.695-1.654c-0.543-1.743-0.538-3.193-0.528-5.827l0.002-1.144
				c0-3.372-2.23-6.232-5.293-7.183c0.181-0.347,0.293-0.735,0.293-1.153c0-1.388-1.129-2.517-2.517-2.517
				c-1.388,0-2.518,1.129-2.518,2.517c0,0.418,0.112,0.806,0.293,1.153C9.697,6.268,7.466,9.128,7.466,12.5l0.004,1.29
				c0.012,2.562,0.019,3.972-0.514,5.681c-0.264,0.847-1.159,1.356-1.694,1.662l-0.56,0.324v3.563h20.594v-3.562L24.724,21.125z
				 M14.168,4.164c0-0.451,0.367-0.817,0.817-0.817s0.817,0.366,0.817,0.817s-0.367,0.817-0.817,0.817S14.168,4.615,14.168,4.164z
				 M23.598,23.32H6.403v-0.884c0.704-0.421,1.776-1.174,2.177-2.46c0.611-1.96,0.604-3.554,0.591-6.195L9.167,12.5
				c0-3.208,2.61-5.819,5.819-5.819c3.207,0,5.817,2.61,5.817,5.819L20.8,13.638c-0.01,2.71-0.016,4.346,0.605,6.338
				c0.4,1.288,1.485,2.042,2.192,2.462V23.32z"
      />
    </SvgIcon>
  );
}

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface ISemaineType {
  idSemaineType: number;
  uuid: string;
  archive: boolean;
  dateCreation: string;
  dateModification: string;
  idCreateur: number;
  libelle: string;
  externalId: number | null;
  idLundi: number | null;
  idMardi: number | null;
  idMercredi: number | null;
  idJeudi: number | null;
  idVendredi: number | null;
  idSamedi: number | null;
  idDimanche: number | null;
  associerMajoration: boolean;
  idMajoration: number | null;
}

type SemainesTypesResponse = ISemaineType[];

export const semainesTypesApi = createApi({
  reducerPath: "semainestypes",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["SemainesTypes"],
  endpoints: (build) => ({
    getSemainesTypes: build.query<SemainesTypesResponse, void>({
      query: () => ({ url: "/semaines-types" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "SemainesTypes", uuid } as const)
              ),
              { type: "SemainesTypes", id: "LIST" },
            ]
          : [{ type: "SemainesTypes", id: "LIST" }],
    }),
    getSemaineType: build.query<ISemaineType, string>({
      query: (uuid) => ({ url: "/semaines-types/" + uuid }),
      providesTags: (result, error, uuid) => [{ type: "SemainesTypes", uuid }],
    }),
    updateSemaineType: build.mutation<ISemaineType, Partial<ISemaineType>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/semaines-types/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "SemainesTypes", uuid },
      ],
    }),
    addSemaineType: build.mutation<ISemaineType, Partial<ISemaineType>>({
      query: (body) => ({
        url: "/semaines-types",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "SemainesTypes", id: "LIST" }],
    }),
    archiveSemaineType: build.mutation<ISemaineType, string | number>({
      query(uuid) {
        return {
          url: "/semaines-types/" + uuid + "/archives",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, uuid) => [
        { type: "SemainesTypes", uuid },
      ],
    }),
  }),
});

export const {
  useGetSemainesTypesQuery,
  useGetSemaineTypeQuery,
  useUpdateSemaineTypeMutation,
  useAddSemaineTypeMutation,
  useArchiveSemaineTypeMutation,
} = semainesTypesApi;

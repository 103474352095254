/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { Navigate } from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";
import {
  HomeAdministrateurPage,
  CreateUtilisateurPage,
  EditUtilisateurPage,
  UtilisateursPage,
  ProfilsPage,
  ContratsPage,
  CategoriesPage,
  EditPopulationPage,
  PopulationsPage,
  ServicesPage,
  CompetencesPage,
  GroupesPage,
  GeneralPage,
  EvenementsPage,
  EditJourneeTypePage,
  JourneesTypesPage,
  EditSemaineTypePage,
  SemainesTypesPage,
  EditRotationTypePage,
  RotationsTypesPage,
  EditCompteurPage,
  CompteursPage,
  ModelesMajorationPage,
  PrestationsPage,
  EditPrestationPage,
  TraductionsPage,
} from "./lazy/administrateur";

export default function Administrateur() {
  const routes = [
    {
      path: "dashboard",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <HomeAdministrateurPage />
        </PrivateRoute>
      ),
    },
    {
      path: "utilisateurs",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <UtilisateursPage />
        </PrivateRoute>
      ),
    },
    {
      path: "utilisateurs/nouveau",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <CreateUtilisateurPage />
        </PrivateRoute>
      ),
    },
    {
      path: "utilisateurs/:uuid",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <EditUtilisateurPage />
        </PrivateRoute>
      ),
    },
    {
      path: "profils",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <ProfilsPage />
        </PrivateRoute>
      ),
    },
    {
      path: "contrats",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <ContratsPage />
        </PrivateRoute>
      ),
    },
    {
      path: "categories",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <CategoriesPage />
        </PrivateRoute>
      ),
    },
    {
      path: "populations",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <PopulationsPage />
        </PrivateRoute>
      ),
    },
    {
      path: "populations/:uuid",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <EditPopulationPage />
        </PrivateRoute>
      ),
    },
    {
      path: "services/*",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <ServicesPage />
        </PrivateRoute>
      ),
      children: [
        {
          path: ":uuid",
          element: (
            <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
              <ServicesPage />
            </PrivateRoute>
          ),
        },
      ],
    },

    {
      path: "competences",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <CompetencesPage />
        </PrivateRoute>
      ),
    },
    {
      path: "groupes",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <GroupesPage />
        </PrivateRoute>
      ),
    },
    {
      path: "general",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <GeneralPage />
        </PrivateRoute>
      ),
    },
    {
      path: "evenements",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <EvenementsPage />
        </PrivateRoute>
      ),
    },
    {
      path: "journeestypes",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <JourneesTypesPage />
        </PrivateRoute>
      ),
    },
    {
      path: "journeestypes/:uuid",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <EditJourneeTypePage />
        </PrivateRoute>
      ),
    },
    {
      path: "semainestypes",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <SemainesTypesPage />
        </PrivateRoute>
      ),
    },
    {
      path: "semainestypes/:uuid",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <EditSemaineTypePage />
        </PrivateRoute>
      ),
    },
    {
      path: "rotationstypes",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <RotationsTypesPage />
        </PrivateRoute>
      ),
    },
    {
      path: "rotationstypes/:uuid",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <EditRotationTypePage />
        </PrivateRoute>
      ),
    },
    {
      path: "compteurs",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <CompteursPage />
        </PrivateRoute>
      ),
    },
    {
      path: "compteurs/nouveau",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <EditCompteurPage />
        </PrivateRoute>
      ),
    },
    {
      path: "modelesmajoration",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <ModelesMajorationPage />
        </PrivateRoute>
      ),
    },
    {
      path: "prestations",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <PrestationsPage />
        </PrivateRoute>
      ),
    },
    {
      path: "prestations/:uuid",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <EditPrestationPage />
        </PrivateRoute>
      ),
    },
    {
      path: "traductions",
      element: (
        <PrivateRoute roles={["ROLE_ADMINISTRATEUR"]}>
          <TraductionsPage />
        </PrivateRoute>
      ),
    },
    {
      path: "*",
      element: <Navigate to="dashboard" />,
    },
  ];

  return { routes };
}

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";

export default styled(Toolbar)(({ theme }) => ({
  // Override media queries injected by theme.mixins.toolbar
  minHeight: 100,
  "@media (min-width:0px) and (orientation: landscape)": {
    minHeight: 80,
  },
  "@media (min-width:600px)": {
    minHeight: 120,
  },
}));

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface ICompetence {
  idCompetence: number;
  uuid: string;
  libelle: string;
  abreviation: string;
  commentaire: string;
  couleur: string;
  archive: boolean;
}

type CompetencesResponse = ICompetence[];

export const competencesApi = createApi({
  reducerPath: "competences",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Competences"],
  endpoints: (build) => ({
    getCompetences: build.query<CompetencesResponse, void>({
      query: () => ({ url: "/competences" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "Competences", uuid } as const)
              ),
              { type: "Competences", id: "LIST" },
            ]
          : [{ type: "Competences", id: "LIST" }],
    }),
    updateCompetence: build.mutation<ICompetence, Partial<ICompetence>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/competences/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Competences", uuid },
      ],
    }),
    addCompetence: build.mutation<ICompetence, Partial<ICompetence>>({
      query: (body) => ({
        url: "/competences",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Competences", id: "LIST" }],
    }),
    archiveCompetence: build.mutation<ICompetence, string>({
      query(uuid) {
        return {
          url: "/competences/" + uuid + "/archives",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, uuid) => [{ type: "Competences", uuid }],
    }),
    deleteCompetence: build.mutation<
      { success: boolean; uuid: string },
      string
    >({
      query(uuid) {
        return {
          url: "/competences/" + uuid,
          method: "delete",
        };
      },
      invalidatesTags: (result, error, uuid) => [{ type: "Competences", uuid }],
    }),
  }),
});

export const {
  useGetCompetencesQuery,
  useUpdateCompetenceMutation,
  useAddCompetenceMutation,
  useArchiveCompetenceMutation,
  useDeleteCompetenceMutation,
} = competencesApi;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { lazy } from "react";

export const HomeUtilisateurPage = lazy(() => import("features/utilisateur"));
export const ComptePage = lazy(() => import("features/utilisateur/ComptePage"));

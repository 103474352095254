import MuiSelect from "@mui/material/Select";
import MuiSwitch from "@mui/material/Switch";
import MuiTextField from "@mui/material/TextField";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiTimePicker from "@mui/lab/TimePicker";
import MuiRadioGroup from "@mui/material/RadioGroup";
import MuiCheckbox from "@mui/material/Checkbox";
import { Controller } from "react-hook-form";
import { forwardRef, RefAttributes, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import { MaskedTextBox as KendoMaskedTextBox } from "@progress/kendo-react-inputs";
import { FilledInput, Input } from "@mui/material";
import CustomEtiquettesField from "features/administrateur/etiquettes/EtiquettesField";
import { DateRangePicker as KendoDateRangePicker } from "@progress/kendo-react-dateinputs";

type Props = {
  [x: string]: any;
};

export const Switch = ({ control, ...props }: Props) => (
  <Controller
    control={control}
    name={props.name}
    render={({ field }) => (
      <MuiSwitch checked={field.value} {...props} {...field} />
    )}
  />
);

export const Select = ({ control, ...props }: Props) => (
  <Controller
    control={control}
    name={props.name}
    render={({ field }) => <MuiSelect {...props} {...field} />}
  />
);

export const TextField = ({
  control,
  helperText,
  variant = "outlined",
  ...props
}: Props) => {
  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field }) => (
        <FormControl fullWidth error={props.error}>
          <InputLabel htmlFor={props.id} variant={variant}>
            {props.label}
          </InputLabel>
          {variant === "standard" ? (
            <Input {...props} {...field} />
          ) : variant === "filled" ? (
            <FilledInput {...props} {...field} />
          ) : (
            <OutlinedInput {...props} {...field} />
          )}
          {props.error &&
            (typeof helperText === "string" ? (
              <FormHelperText error>
                <>{helperText}</>
              </FormHelperText>
            ) : (
              helperText.map((error: string, key: number) => (
                <FormHelperText key={key} error>
                  <>{error}</>
                </FormHelperText>
              ))
            ))}
        </FormControl>
      )}
    />
  );
};

export const ToggleButtonGroup = ({ control, ...props }: Props) => (
  <Controller
    control={control}
    name={props.name}
    render={({ field }) => <MuiToggleButtonGroup {...props} {...field} />}
  />
);

export const TimePicker = ({ control, errors, ...props }: Props) => {
  const ref = useRef();
  const RenderInput = forwardRef((params, ref) => (
    <MuiTextField
      inputRef={ref}
      fullWidth
      error={!!errors[props.name]}
      helperText={!!errors[props.name] && errors[props.name].message}
      {...params}
    />
  ));

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field }) => (
        <MuiTimePicker
          renderInput={(
            params: JSX.IntrinsicAttributes & RefAttributes<unknown>
          ) => <RenderInput ref={ref} {...params} />}
          {...props}
          {...field}
        />
      )}
    />
  );
};

export const DateRangePicker = ({ control, ...props }: Props) => {
  console.log("props", props);
  return (
    <Controller
      rules={{ required: true }}
      control={control}
      name={props.name}
      render={({ field }) => {
        console.log("field", field);
        const { value, ...rest } = field;
        let valueFormatted = Array.isArray(value)
          ? {
              start: value[0] ? new Date(value[0]) : null,
              end: value[1] ? new Date(value[1]) : null,
            }
          : value;
        return (
          <KendoDateRangePicker value={valueFormatted} {...props} {...rest} />
        );
      }}
    />
  );
};

export const RadioGroup = ({ control, ...props }: Props) => (
  <Controller
    rules={{ required: true }}
    control={control}
    name={props.name}
    render={({ field }) => <MuiRadioGroup {...props} {...field} />}
  />
);

export const Checkbox = ({ control, ...props }: Props) => (
  <Controller
    rules={{ required: true }}
    control={control}
    name={props.name}
    render={({ field }) => (
      <MuiCheckbox
        {...props}
        {...field}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          field.onChange(e.target.checked)
        }
        checked={field.value}
      />
    )}
  />
);

export const MaskedTextBox = ({ control, ...props }: Props) => (
  <Controller
    control={control}
    name={props.name}
    render={({ field }) => (
      <KendoMaskedTextBox
        width="100%"
        fillMode="outline"
        {...props}
        {...field}
      />
    )}
  />
);

export const EtiquettesField = ({ control, ...props }: Props) => (
  <Controller
    control={control}
    name={props.name}
    render={({ field }) => <CustomEtiquettesField {...props} {...field} />}
  />
);

import {
  Button,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAddEtiquetteMutation } from "services/etiquettes";
import AddIcon from "@mui/icons-material/Add";
import { useFieldArray } from "react-hook-form";
import { MultiSelect, MultiSelectProps } from "@progress/kendo-react-dropdowns";

const AddButton = (props: IconButtonProps) => {
  return (
    <IconButton {...props}>
      <AddIcon />
    </IconButton>
  );
};

export default function EtiquettesField(props: MultiSelectProps) {
  const { t } = useTranslation();

  const [addEtiquette] = useAddEtiquetteMutation();

  const { append } = useFieldArray({
    name: "etiquettes",
  });

  // Bouton d'édition
  const [open, setOpen] = useState<boolean>(false);
  const handleClickToggle = () => {
    setOpen((prev) => !prev);
  };
  const EditButton = () => {
    return (
      <Button variant="contained" size="small" onClick={handleClickToggle}>
        {t("éditer")}
      </Button>
    );
  };

  // Ajout d'une étiquette
  const [libelle, setLibelle] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLibelle(event.target.value);
  };
  const handleSubmit = (libelle: string) => {
    addEtiquette({
      libelle,
      type: "JourneeType",
    })
      .unwrap()
      .then(() => {
        append(libelle);
        setLibelle("");
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Collapse orientation="horizontal" in={open}>
            <TextField
              id="addTag"
              name="addTag"
              value={libelle}
              onChange={handleChange}
              label={t("Créer une étiquette")}
              size="small"
              InputProps={{
                endAdornment: (
                  <AddButton onClick={() => handleSubmit(libelle)} />
                ),
              }}
            />
          </Collapse>
          <EditButton />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MultiSelect {...props} />
      </Grid>
    </Grid>
  );
}

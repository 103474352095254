import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export enum ArrondirCreditDetail {
  ENTIER_SUPERIEUR = 1,
  DEMI_SUPERIEUR = 2,
  QUART_SUPERIEUR = 4,
}

type AffichageProps = {
  affichageMenuDeroulant: boolean;
  afficherJauge: boolean;
  arrondirCredit: boolean;
  arrondirCreditDetail: ArrondirCreditDetail;
  afficherSolde: boolean;
  afficherCompteur: boolean;
  affichageUtilisateurConsulterSolde: boolean;
  affichageUtilisateurTableauRepartition: boolean;
  affichageUtilisateurListeRepartition: boolean;
  affichageUtilisateurPlanningLibelle: boolean;
  affichageManagerConsulterSolde: boolean;
  affichageManagerTableauRepartition: boolean;
  affichageManagerListeRepartition: boolean;
  affichageAdminTableauRepartition: boolean;
  affichageAdminListeRepartition: boolean;
  filtreIdPopulation: string;
  valeurAbsolue: boolean;
  affichageUtilisateurCalculSolde: boolean;
  affichageManagerCalculSolde: boolean;
  affichageJourHeureUtilisateur: number;
  affichageJourHeureManager: number;
};

export enum PointageAuto {
  OUI = 0,
  NON = 1,
  OUI_INVERSE = 2,
}

type CalculProps = {
  travailEffectifDetail: number;
  incrementationFrequence: string;
  incrementationFonction: string;
  dateDebut: string;
  dateFin: string;
  idCompteurTransfert: number | null;
  idCompteurMaitre: number | null;
  debutAMFormation: Date;
  finAMFormation: Date;
  debutPMFormation: Date;
  finPMFormation: Date;
  nbHeuresFormation: string;
  compteurAcrediter: number | string;
  debutAMAstreinte: Date;
  finAMAstreinte: Date;
  debutPMAstreinte: Date;
  finPMAstreinte: Date;
  nbHeuresAstreinte: string;
  valeurTheoJourneeTypeAzero: boolean;
  valeurTravailEffectifAzero: boolean;
  majoration: boolean;
  majorationIdCompteur: number;
  majorationPourcentage: number;
  astreinteReel: boolean;
  deduirePrevisionnel: boolean;
  decomptePauses: boolean;
  pointageAuto: PointageAuto;
  travailEffectif: boolean;
};

export enum JourOuvre {
  JOURS_OUVRABLES = "jours_ouvrables",
  JOURS_OUVRES = "jours_ouvres",
  TOUTE_LA_PERIODE = "toute_la_periode",
  CINQ_JOURS_PAR_SEMAINE = "cinq_jours_par_semaine",
  SIX_JOURS_PAR_SEPT_JOURS = "six_jours_par_sept_jours",
  JOURS_FERIES = "jours_feries",
  WEEKEND = "weekend",
  WEEKEND_FERIES = "weekend_feries",
}

export enum ActionHorsPeriodeConsommation {
  AUCUN_MESSAGE = "aucun_message",
  MESSAGE_ALERTE = "message_alerte",
  BLOCAGE_DEMANDE = "blocage_demande",
  BLOCAGE_DEMANDE_A_CHEVAL = "blocage_demande_a_cheval",
}

export enum ActionDemiJournee {
  AUCUN_MESSAGE = "aucun_message",
  MESSAGE_ALERTE = "message_alerte",
  BLOCAGE_DEMANDE = "blocage_demande",
}

export enum ActionDebit {
  AUCUN_MESSAGE = "aucun_message",
  MESSAGE_ALERTE = "message_alerte",
  BLOCAGE_DEMANDE = "blocage_demande",
  UTILISATION_AUTOMATIQUE_COMPTEUR = "utilisation_automatique_compteur",
}

export enum ValeurConge {
  DEFAUT = "Defaut",
  PERSONNALISEE = "Personnalisee",
}

export enum ForfaitRepartition {
  REPARTITION_AUTOMATIQUE = "automatique",
  REPARTITION_DEBUT_ABSENCE = "debut",
  REPARTITION_FIN_ABSENCE = "fin",
  REPARTITION_DEBUT_FIN_ABSENCE = "debut_fin",
  REPARTITION_CENTRE_ABSENCE = "centre",
}

export enum SeuilMaxDemande {
  AUCUN = "Aucun",
  DEMANDE = "Demande",
  MOIS = "Mois",
  DEMANDE_MOIS = "Demande_mois",
  SEMAINE = "Semaine",
}

export enum NbMaxDemande {
  AUCUN = "Aucun",
  TOTAL = "Total",
  MOIS = "Mois",
}

type ConsommationProps = {
  dateConso: string[] | null[];
  autoriserDemiJournee: boolean;
  validationAutomatique: boolean;
  actionDemiJournee2jours: boolean;
  actionDebitEnCours: boolean;
  compteurActionDebit: number;
  periodeReflexion: (number | null | undefined)[];
  valeurCongePersoJourComplet: number;
  valeurCongePersoJourMatin: number;
  valeurCongePersoJourAprem: number;
  valeurCongePersoHeuresJourComplet: Date | string;
  valeurCongePersoHeuresMatin: Date | string;
  valeurCongePersoHeuresAprem: Date | string;
  forfait: boolean;
  forfaitJour: number;
  forfaitHeure: Date | string;
  messageConge: boolean;
  messageCongeText: string;
  forfaitRepartition: ForfaitRepartition;
  jourOuvre: JourOuvre;
  actionHorsPeriodeConsommation: ActionHorsPeriodeConsommation;
  actionDemiJournee: ActionDemiJournee;
  actionDebit: ActionDebit;
  seuilMaxDemande: SeuilMaxDemande;
  seuilMaxDemandeValue: number;
  nbMaxDemande: NbMaxDemande;
  nbMaxDemandeValue: number;
  valeurConge: ValeurConge;
};

type ExportPaieProps = {
  exporterVersPaie: boolean;
  compteurRemunere: boolean;
  exporterPaieInverse: boolean;
};

export enum Categorie {
  CONGE = "Conge",
  ABSENCE = "Absence",
  INDIVIDUEL = "Individuel",
}

export enum Unite {
  JOURS = "Jours",
  HEURES = "Heures",
}

export enum DecompteHeures {
  JOURS = "Jours",
  HEURES = "Heures",
  JOURS_ET_HEURES = "JoursEtHeures",
}

export enum TypeAbsence {
  ABSENCE = "Absence",
  ACCIDENT = "Accident",
  FORMATION = "Formation",
  ASTREINTE = "Astreinte",
  EVENEMENT = "Evenement",
}

export interface ICompteur {
  idCompteur: number;
  uuid: string;
  archive: boolean;
  libelle: string;
  position: number;
  libelleCourt: string;
  compteurObligatoire: boolean;
  numero: string;
  couleur: string;
  typeCompteur: Categorie;
  typeAbsence: TypeAbsence;
  unite: Unite;
  seuilCompteurHeure: (string | null)[];
  seuilCompteurJour: (number | null)[];
  equivalenceJourneeHeures: string;
  equivalenceDemiJourneeHeures: string;
  actif: boolean;
  decompteHeures: DecompteHeures;
  affichage: AffichageProps;
  calculs: CalculProps;
  consommation: ConsommationProps;
  exportPaie: ExportPaieProps;
}

type CompteursResponse = ICompteur[];

export const compteursApi = createApi({
  reducerPath: "compteurs",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Compteurs"],
  endpoints: (build) => ({
    getCompteurs: build.query<CompteursResponse, void>({
      query: () => ({ url: "/compteurs" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "Compteurs", uuid } as const)
              ),
              { type: "Compteurs", id: "LIST" },
            ]
          : [{ type: "Compteurs", id: "LIST" }],
    }),
    getCompteur: build.query<ICompteur, string>({
      query: (uuid) => ({ url: "/compteurs/" + uuid }),
      providesTags: (result, error, uuid) => [{ type: "Compteurs", uuid }],
    }),
    updateCompteur: build.mutation<ICompteur, Partial<ICompteur>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/compteurs/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Compteurs", uuid },
      ],
    }),
    addCompteur: build.mutation<ICompteur, Partial<ICompteur>>({
      query: (body) => ({
        url: "/compteurs",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Compteurs", id: "LIST" }],
    }),
    archiveCompteur: build.mutation<ICompteur, string>({
      query(uuid) {
        return {
          url: "/compteurs/" + uuid + "/archives",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, uuid) => [{ type: "Compteurs", uuid }],
    }),
  }),
});

export const {
  useGetCompteursQuery,
  useGetCompteurQuery,
  useUpdateCompteurMutation,
  useAddCompteurMutation,
  useArchiveCompteurMutation,
} = compteursApi;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { PropsWithChildren, Suspense } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { isRouteAuthorized } from "helpers";

export interface IPrivateRoute extends PropsWithChildren {
  roles: string[];
}

export default function PrivateRoute({ roles, children }: IPrivateRoute) {
  const auth = useAuth();

  return auth.isAuthenticated ? (
    isRouteAuthorized(auth.session?.roles, roles) ? (
      <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
    ) : (
      <Navigate to="/" />
    )
  ) : (
    <Navigate to="/" />
  );
}

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "assets/images/logo-e2time.png";
import Role from "./Role";
import Traduction from "./Traduction";
import Compte from "./Compte";
import Box from "@mui/material/Box";
import Message from "./Message";
import Alerte from "./Alerte";
import StyledToolbar from "../StyledToolbar";
import { Link } from "react-router-dom";
import { useRoleTheme } from "hooks/useRoleTheme";
import { useAppDispatch } from "store";
import { toggleDrawer } from "../dashboardSlice";

export default function DashboardAppBar() {
  const roleTheme = useRoleTheme();
  const dispatch = useAppDispatch();

  const homeUrls = {
    ADMINISTRATEUR: "./a/dashboard",
    MANAGER: "./m/dashboard",
    UTILISATEUR: "./u/dashboard",
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "white",
        color: "text.primary",
      }}
    >
      <StyledToolbar>
        <IconButton
          edge="start"
          sx={{
            marginRight: 2,
            display: { md: "none" },
          }}
          color="inherit"
          aria-label="menu"
          onClick={() => dispatch(toggleDrawer())}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Link to={homeUrls[roleTheme]}>
          <Box
            component="img"
            src={logo}
            alt="logo"
            sx={{ maxWidth: { xs: "100px", md: "160px" } }}
          />
        </Link>
        <Box sx={{ flexGrow: 1 }} />
        <Role />
        <Message />
        <Alerte />
        <Compte />
        <Traduction />
      </StyledToolbar>
    </AppBar>
  );
}

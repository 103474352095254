import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IProfil {
  idProfil: number;
  uuid: string;
  nom: string;
  nbHeures: string;
  nbJours: number;
  archive: boolean;
  externalId: number;
}

type ProfilsResponse = IProfil[];

export const profilsApi = createApi({
  reducerPath: "profils",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Profils"],
  endpoints: (build) => ({
    getProfils: build.query<ProfilsResponse, void>({
      query: () => ({ url: "/profil-noms" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ uuid }) => ({ type: "Profils", uuid } as const)),
              { type: "Profils", id: "LIST" },
            ]
          : [{ type: "Profils", id: "LIST" }],
    }),
    updateProfil: build.mutation<IProfil, Partial<IProfil>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/profil-nom/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [{ type: "Profils", uuid }],
    }),
    addProfil: build.mutation<IProfil, Partial<IProfil>>({
      query: (body) => ({
        url: "/profil-nom",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Profils", id: "LIST" }],
    }),
    deleteProfil: build.mutation<{ success: boolean; uuid: any }, string>({
      query(uuid) {
        return {
          url: "/profil-nom/" + uuid,
          method: "delete",
        };
      },
      invalidatesTags: (result, error, uuid) => [{ type: "Profils", uuid }],
    }),
  }),
});

export const {
  useGetProfilsQuery,
  useUpdateProfilMutation,
  useAddProfilMutation,
  useDeleteProfilMutation,
} = profilsApi;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import Routes from "./routes";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import Theme from "./assets/themes/Theme";
import { matomo } from "./constants/Matomo";
import AuthProvider from "./contexts/AuthProvider";
import { MatomoProvider } from "@datapunt/matomo-tracker-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SnackbarProvider } from "notistack";
import i18n from "i18n";
import { LANGUES } from "constants/Multilingual";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <MatomoProvider value={matomo}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={
          LANGUES.find((lang) => lang.name === i18n.language)?.locale || "fr"
        }
      >
        <ThemeProvider theme={Theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={1}>
            <AuthProvider>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </MatomoProvider>
  );
}

export default App;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Compteur(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <polygon
        points="6.236,10.678 8.68,10.678 8.68,21.529 9.88,21.529 9.88,9.478 5.036,9.478 5.036,21.529 
				6.236,21.529 			"
      />
      <polygon
        points="11.962,7.555 14.406,7.555 14.406,13.201 15.606,13.201 15.606,6.354 10.762,6.354 
				10.762,21.529 11.962,21.529 			"
      />
      <polygon
        points="17.688,3.911 20.131,3.911 20.131,12.68 21.331,12.68 21.331,2.711 16.488,2.711 16.488,12.68 
				17.688,12.68 			"
      />
      <rect x="3.034" y="21.97" width="9.369" height="1.2" />
      <path
        d="M20.211,13.121c-4.062,0-7.367,3.305-7.367,7.367s3.305,7.367,7.367,7.367s7.367-3.305,7.367-7.367
				S24.273,13.121,20.211,13.121z M20.211,26.654c-3.4,0-6.167-2.766-6.167-6.167s2.766-6.167,6.167-6.167s6.167,2.766,6.167,6.167
				S23.611,26.654,20.211,26.654z"
      />
      <path
        d="M22.746,17.285c0.343,0.272,0.638,0.594,0.877,0.957l1.002-0.66c-0.31-0.47-0.69-0.886-1.133-1.237
				c-0.942-0.747-2.077-1.142-3.281-1.142c-2.914,0-5.285,2.371-5.285,5.285h1.2c0-2.252,1.832-4.085,4.084-4.085
				C21.142,16.403,22.018,16.708,22.746,17.285z"
      />
      <path
        d="M25.037,18.46l-3.554,2.892c-0.358-0.263-0.795-0.423-1.272-0.423c-1.192,0-2.162,0.97-2.162,2.161
				c0,1.192,0.97,2.162,2.162,2.162c1.191,0,2.161-0.97,2.161-2.162c0-0.279-0.058-0.543-0.154-0.788l3.577-2.911L25.037,18.46z
				 M20.211,24.052c-0.53,0-0.962-0.432-0.962-0.962c0-0.53,0.432-0.961,0.962-0.961c0.53,0,0.961,0.431,0.961,0.961
				C21.172,23.62,20.741,24.052,20.211,24.052z"
      />
    </SvgIcon>
  );
}

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IPopulation {
  idPopulation: number;
  uuid: string;
  utilisateurId: number;
  serviceId: number;
  dateCreation: string;
  dateModification: string;
  libelle: string;
  commentaire: string;
  optionUtilisateurs: number;
  archive: boolean;
  externalId: number | null;
}

export interface IPopulationForm {
  utilisateurUuid: string;
  serviceUuid: string;
  libelle: string;
  commentaire: string;
  optionUtilisateurs: number;
  archive: boolean;
  utilisateurs: (string | number)[];
  services: (string | number)[];
  csp: (string | number)[];
  profils: (string | number)[];
  contrats: (string | number)[];
}

type PopulationsResponse = IPopulation[];

export const populationsApi = createApi({
  reducerPath: "populations",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Populations"],
  endpoints: (build) => ({
    getPopulations: build.query<PopulationsResponse, void>({
      query: () => ({ url: "/populations" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "Populations", uuid } as const)
              ),
              { type: "Populations", id: "LIST" },
            ]
          : [{ type: "Populations", id: "LIST" }],
    }),
    getPopulation: build.query<IPopulationForm, string>({
      query: (uuid) => ({ url: "/populations/" + uuid }),
      providesTags: (result, error, uuid) => [{ type: "Populations", uuid }],
    }),
    updatePopulation: build.mutation<
      IPopulationForm,
      Partial<IPopulationForm> & Pick<IPopulation, "uuid">
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/populations/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Populations", uuid },
      ],
    }),
    updatePopulationUtilisateurs: build.mutation<
      IPopulationForm,
      Partial<IPopulationForm> & Pick<IPopulation, "uuid">
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/populations/" + uuid + "/utilisateurs",
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Populations", uuid },
      ],
    }),
    updatePopulationServices: build.mutation<
      IPopulationForm,
      Partial<IPopulationForm> & Pick<IPopulation, "uuid">
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/populations/" + uuid + "/services",
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Populations", uuid },
      ],
    }),
    updatePopulationCsp: build.mutation<
      IPopulationForm,
      Partial<IPopulationForm> & Pick<IPopulation, "uuid">
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/populations/" + uuid + "/csp",
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Populations", uuid },
      ],
    }),
    updatePopulationProfils: build.mutation<
      IPopulationForm,
      Partial<IPopulationForm> & Pick<IPopulation, "uuid">
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/populations/" + uuid + "/profils",
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Populations", uuid },
      ],
    }),
    updatePopulationContrats: build.mutation<
      IPopulationForm,
      Partial<IPopulationForm> & Pick<IPopulation, "uuid">
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/populations/" + uuid + "/contrats",
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Populations", uuid },
      ],
    }),
    addPopulation: build.mutation<IPopulationForm, Partial<IPopulationForm>>({
      query: (body) => ({
        url: "/populations",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Populations", id: "LIST" }],
    }),
    archivePopulation: build.mutation<IPopulation, string | number>({
      query(uuid) {
        return {
          url: "/populations/" + uuid + "/archives",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, uuid) => [{ type: "Populations", uuid }],
    }),
  }),
});

export const {
  useGetPopulationsQuery,
  useGetPopulationQuery,
  useUpdatePopulationMutation,
  useUpdatePopulationUtilisateursMutation,
  useUpdatePopulationServicesMutation,
  useUpdatePopulationCspMutation,
  useUpdatePopulationProfilsMutation,
  useUpdatePopulationContratsMutation,
  useAddPopulationMutation,
  useArchivePopulationMutation,
} = populationsApi;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import { Link, useLocation } from "react-router-dom";
import { AccountCircle, ExpandMore } from "@mui/icons-material";
import UtilisateurIcon from "assets/icons/Utilisateur";
import PlanningIcon from "assets/icons/Planning";
import CompteurIcon from "assets/icons/Compteur";
import ParametrageGeneralIcon from "assets/icons/ParametrageGeneral";
import ParametrageModuleIcon from "assets/icons/ParametrageModule";
import { useDashboard } from "hooks/useDashboard";
import { useAppDispatch } from "store";
import { toggleAdministrateurItem } from "../dashboardSlice";

export default function AdministrateurMenu() {
  const { t } = useTranslation();
  let { pathname } = useLocation();
  const dashboard = useDashboard();
  const dispatch = useAppDispatch();

  const administrateurMenu = [
    {
      id: "utilisateurs",
      title: t("Utilisateurs"),
      children: [
        { title: t("Utilisateurs"), pathname: "a/utilisateurs" },
        { title: t("Populations"), pathname: "a/populations" },
        { title: t("Services"), pathname: "a/services" },
        { title: t("Groupes"), pathname: "a/groupes" },
      ],
    },
    {
      id: "planning",
      title: t("Planning"),
      children: [
        { title: t("Journées types"), pathname: "a/journeestypes" },
        { title: t("Semaines types"), pathname: "a/semainestypes" },
        { title: t("Rotations types"), pathname: "a/rotationstypes" },
      ],
    },
    {
      id: "compteurs",
      title: t("Compteurs"),
      children: [
        { title: t("Compteurs"), pathname: "a/compteurs" },
        { title: t("Modèles de majoration"), pathname: "a/modelesmajoration" },
      ],
    },
    {
      id: "parametrageGeneral",
      title: t("Paramétrage général"),
      children: [
        { title: t("Général"), pathname: "a/general" },
        { title: t("Profils"), pathname: "a/profils" },
        { title: t("Contrats"), pathname: "a/contrats" },
        { title: t("Categories"), pathname: "a/categories" },
        { title: t("Traductions"), pathname: "a/traductions" },
      ],
    },
    {
      id: "parametrageModules",
      title: t("Paramétrage des modules"),
      children: [
        { title: t("Compétences"), pathname: "a/competences" },
        { title: t("Événements"), pathname: "a/evenements" },
        { title: t("Prestations"), pathname: "a/prestations" },
      ],
    },
  ];

  return (
    <List>
      {administrateurMenu.map((item, itemKey) => (
        <Fragment key={itemKey}>
          <ListItemButton
            onClick={() => dispatch(toggleAdministrateurItem(item.id))}
            sx={{ px: 3 }}
          >
            <ListItemIcon sx={{ color: "inherit" }}>
              {item.id === "utilisateurs" ? (
                <UtilisateurIcon sx={{ fontSize: "2rem" }} />
              ) : item.id === "planning" ? (
                <PlanningIcon sx={{ fontSize: "2rem" }} />
              ) : item.id === "compteurs" ? (
                <CompteurIcon sx={{ fontSize: "2rem" }} />
              ) : item.id === "parametrageGeneral" ? (
                <ParametrageGeneralIcon sx={{ fontSize: "2rem" }} />
              ) : item.id === "parametrageModules" ? (
                <ParametrageModuleIcon sx={{ fontSize: "2rem" }} />
              ) : (
                <AccountCircle />
              )}
            </ListItemIcon>
            <ListItemText
              primary={item.title}
              primaryTypographyProps={{
                sx: { fontWeight: "bold", fontSize: "0.9rem" },
              }}
            />
            <ExpandMore
              sx={(theme) => ({
                transform: dashboard.openAdministrateurItem[item.id]
                  ? "rotateZ(360deg)"
                  : "rotateZ(270deg)",
                transition: theme.transitions.duration.standard + "ms",
              })}
            />
          </ListItemButton>
          <Collapse
            in={dashboard.openAdministrateurItem[item.id]}
            timeout="auto"
            unmountOnExit
            sx={{ backgroundColor: "secondary.main" }}
          >
            <List component="div" disablePadding>
              {item.children.map((subItem, subItemKey) => (
                <Box
                  key={subItemKey}
                  sx={{
                    px: 3,
                    borderLeft:
                      subItem.pathname === pathname
                        ? "5px solid white"
                        : "none",
                  }}
                >
                  <ListItemButton
                    component={Link}
                    to={subItem.pathname}
                    state={{ title: subItem.title }}
                  >
                    <ListItemText
                      primary={subItem.title}
                      primaryTypographyProps={{
                        sx: { fontWeight: "bold", fontSize: "0.9rem" },
                      }}
                    />
                  </ListItemButton>
                  <Divider sx={{ borderColor: "primary.main" }} />
                </Box>
              ))}
            </List>
          </Collapse>
        </Fragment>
      ))}
    </List>
  );
}

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IUtilisateurVisibilite {
  type: string;
  populationUuid: string;
  personnaliseServices: string[];
  personnaliseUtilisateurs: string[];
}

export const utilisateurVisibilitesApi = createApi({
  reducerPath: "utilisateurVisibilites",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["UtilisateurVisibilites"],
  endpoints: (build) => ({
    getUtilisateurVisibilite: build.query<IUtilisateurVisibilite, string>({
      query: (uuid) => ({ url: "/visibilites/utilisateurs/" + uuid }),
      providesTags: (result, error, uuid) => [
        { type: "UtilisateurVisibilites", uuid },
      ],
    }),
    updateUtilisateurVisibilite: build.mutation<
      IUtilisateurVisibilite,
      Partial<IUtilisateurVisibilite> & { uuid: string }
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/visibilites/utilisateurs/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "UtilisateurVisibilites", uuid },
      ],
    }),
  }),
});

export const {
  useGetUtilisateurVisibiliteQuery,
  useUpdateUtilisateurVisibiliteMutation,
} = utilisateurVisibilitesApi;

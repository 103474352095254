/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    openDrawer: false,
    openMenu: true,
    openAdministrateurItem: {
      utilisateurs: false,
      planning: false,
      compteurs: false,
      parametrageGeneral: false,
      parametrageModules: false,
    },
  },
  reducers: {
    openDrawer: (state) => {
      return { ...state, openDrawer: true };
    },
    closeDrawer: (state) => {
      return { ...state, openDrawer: false };
    },
    toggleDrawer: (state) => {
      return { ...state, openDrawer: !state.openDrawer };
    },
    openMenu: (state) => {
      return { ...state, openMenu: true };
    },
    toggleMenu: (state) => {
      return {
        ...state,
        openMenu: !state.openMenu,
        openAdministrateurItem: {
          utilisateurs: false,
          planning: false,
          compteurs: false,
          parametrageGeneral: false,
          parametrageModules: false,
        },
      };
    },
    toggleAdministrateurItem: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        openMenu: true,
        openAdministrateurItem: {
          ...state.openAdministrateurItem,
          [action.payload]: !state.openAdministrateurItem[action.payload],
        },
      };
    },
  },
});

export const {
  openDrawer,
  closeDrawer,
  toggleDrawer,
  openMenu,
  toggleMenu,
  toggleAdministrateurItem,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IPrestation {
  idPrestation: number;
  uuid: string;
  libelle: string;
  reference: string;
  typePointage: string;
  travailEffectif?: boolean;
  geolocalisation?: boolean;
  adresseDebut: string;
  adresseFin: string;
  dureeFixe?: boolean;
  duree: string;
  adresse: string;
  traitement: string;
  contact: string;
  telephone: string;
  email: string;
  commentaire: string;
  couleur: string;
  deliverySequence: number | null;
  index: number | null;
  client: string | null;
  volume: number | null;
  codeClient: string | null;
  archive?: boolean;
  competences?: string[];
}

type PrestationsResponse = IPrestation[];

export const prestationsApi = createApi({
  reducerPath: "prestations",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Prestations"],
  endpoints: (build) => ({
    getPrestations: build.query<PrestationsResponse, void>({
      query: () => ({ url: "/prestations" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "Prestations", uuid } as const)
              ),
              { type: "Prestations", id: "LIST" },
            ]
          : [{ type: "Prestations", id: "LIST" }],
    }),
    getPrestation: build.query<IPrestation, string>({
      query: (uuid) => ({ url: "/prestations/" + uuid }),
      providesTags: (result, error, uuid) => [{ type: "Prestations", uuid }],
    }),
    getCompetencesByPrestation: build.query<IPrestation, string>({
      query: (uuid) => ({ url: "/prestations/" + uuid + "/competences" }),
      providesTags: (result, error, uuid) => [{ type: "Prestations", uuid }],
    }),
    updatePrestation: build.mutation<IPrestation, Partial<IPrestation>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/prestations/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Prestations", uuid },
      ],
    }),
    addPrestation: build.mutation<IPrestation, Partial<IPrestation>>({
      query: (body) => ({
        url: "/prestations",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Prestations", id: "LIST" }],
    }),
    addCompetencesByPrestation: build.mutation<
      IPrestation,
      Partial<IPrestation>
    >({
      query: ({ uuid, ...body }) => ({
        url: "/prestations/" + uuid + "/competences",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Prestations", id: "LIST" }],
    }),
    archivePrestation: build.mutation<IPrestation, string | number>({
      query(uuid) {
        return {
          url: "/prestations/" + uuid + "/archives",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, uuid) => [{ type: "Prestations", uuid }],
    }),
    deletePrestationCompetence: build.mutation<
      { success: boolean; uuid: string },
      string
    >({
      query(uuid) {
        return {
          url: "/prestations-competences/" + uuid,
          method: "delete",
        };
      },
      invalidatesTags: (result, error, uuid) => [{ type: "Prestations", uuid }],
    }),
  }),
});

export const {
  useGetPrestationsQuery,
  useGetPrestationQuery,
  useGetCompetencesByPrestationQuery,
  useUpdatePrestationMutation,
  useAddPrestationMutation,
  useAddCompetencesByPrestationMutation,
  useArchivePrestationMutation,
  useDeletePrestationCompetenceMutation,
} = prestationsApi;

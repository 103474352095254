/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import ResetPasswordForm from "./ResetPasswordForm";
import { Box, List, ListItem, ListItemText } from "@mui/material";

export default function ResetPasswordPage() {
  // Initialisation de la traduction
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        sx={{ mt: 6, color: "primary.main" }}
      >
        {t("Réinitialisation")}
      </Typography>
      <Box sx={{ mt: 6, mb: 4 }}>
        <Typography variant="body1" paragraph>
          {t("Veuillez enregistrer votre nouveau mot de passe.")}
        </Typography>
        <Typography variant="body1" paragraph>
          {t("Merci de respecter les règles de construction suivantes :")}
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary={t("Au moins 8 caractères.")} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t("Au moins 1 chiffre.")} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t("Au moins une majuscule.")} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t("Au moins un caractère spécial.")} />
          </ListItem>
        </List>
      </Box>

      <ResetPasswordForm />
    </>
  );
}

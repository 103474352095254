import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IContrat {
  idContrat: number;
  uuid: string;
  libelle: string;
  abreviation: string;
  essai: number;
  preavis: number;
  archive: boolean;
}

type ContratsResponse = IContrat[];

export const contratsApi = createApi({
  reducerPath: "contrats",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Contrats"],
  endpoints: (build) => ({
    getContrats: build.query<ContratsResponse, void>({
      query: () => ({ url: "/contrats" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "Contrats", uuid } as const)
              ),
              { type: "Contrats", id: "LIST" },
            ]
          : [{ type: "Contrats", id: "LIST" }],
    }),
    updateContrat: build.mutation<IContrat, Partial<IContrat>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/contrats/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Contrats", uuid },
      ],
    }),
    addContrat: build.mutation<IContrat, Partial<IContrat>>({
      query: (body) => ({
        url: "/contrats",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Contrats", id: "LIST" }],
    }),
    archiveContrat: build.mutation<IContrat, Partial<IContrat>>({
      query(uuid) {
        return {
          url: "/contrats/" + uuid + "/archives",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Contrats", uuid },
      ],
    }),
  }),
});

export const {
  useGetContratsQuery,
  useUpdateContratMutation,
  useArchiveContratMutation,
  useAddContratMutation,
} = contratsApi;

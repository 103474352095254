/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function Menu(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <rect x="1.935" y="21.78" width="24.2" height="2.621" />
      <rect x="1.935" y="5.599" width="24.2" height="2.62" />
      <rect x="1.935" y="11.069" width="15.994" height="2.62" />
      <rect x="1.935" y="16.349" width="15.994" height="2.621" />
      <polygon points="28.065,15.006 24.54,12.971 21.015,10.936 21.015,15.006 21.015,19.075 24.54,17.04 			" />
    </SvgIcon>
  );
}

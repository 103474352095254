/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

export default function LoginPage() {
  // Initialisation de la traduction
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        sx={{ mt: 6, color: "primary.main" }}
      >
        {t("Bienvenue à vous, c'est parti !")}
      </Typography>
      <Typography variant="body1" component="p" sx={{ my: 6 }}>
        {t(
          "Merci de vous identifier en renseignant votre identifiant et votre mot de passe."
        )}
      </Typography>
      <LoginForm />
    </>
  );
}

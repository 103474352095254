import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from ".";

export interface IDataProps {
  roles: string[];
  refreshToken: string;
}

export interface ILoginResponse {
  token: string;
  data: IDataProps;
}

export interface ILoginForm {
  username: string;
  password: string;
  societe: string;
}

export interface IRequestPasswordForm {
  email: string;
  login: string;
  societe: string;
}

export interface IResetPasswordForm {
  token: string;
  password: string;
  confirmation: string;
  societe: string;
}

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: baseQuery,
  tagTypes: ["Auth"],
  endpoints: (builder) => ({
    login: builder.mutation<ILoginResponse, ILoginForm>({
      query: (credentials) => ({
        url: "/login",
        method: "post",
        data: credentials,
      }),
    }),
    refreshToken: builder.mutation<ILoginResponse, void>({
      query: () => ({
        url: "/refresh-token",
        method: "post",
      }),
    }),
    requestPassword: builder.mutation<void, IRequestPasswordForm>({
      query: (data) => ({
        url: "/reinitialisation-mot-de-passes/demandes",
        method: "post",
        data,
      }),
    }),
    resetPassword: builder.mutation<void, IResetPasswordForm>({
      query: ({ token, ...data }) => ({
        url: "/reinitialisation-mot-de-passes/" + token,
        method: "patch",
        data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useRequestPasswordMutation,
  useResetPasswordMutation,
} = authApi;

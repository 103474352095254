import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IGroupe {
  idGroupe: number;
  uuid: string;
  libelle: string;
  archive: boolean;
}

type GroupesResponse = IGroupe[];

export const groupesApi = createApi({
  reducerPath: "groupes",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Groupes"],
  endpoints: (build) => ({
    getGroupes: build.query<GroupesResponse, void>({
      query: () => ({ url: "/groupes" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ uuid }) => ({ type: "Groupes", uuid } as const)),
              { type: "Groupes", id: "LIST" },
            ]
          : [{ type: "Groupes", id: "LIST" }],
    }),
    updateGroupe: build.mutation<IGroupe, Partial<IGroupe>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/groupes/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [{ type: "Groupes", uuid }],
    }),
    addGroupe: build.mutation<IGroupe, Partial<IGroupe>>({
      query: (body) => ({
        url: "/groupes",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Groupes", id: "LIST" }],
    }),
    archiveGroupe: build.mutation<IGroupe, string>({
      query(uuid) {
        return {
          url: "/groupes/" + uuid + "/archives",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, uuid) => [{ type: "Groupes", uuid }],
    }),
  }),
});

export const {
  useGetGroupesQuery,
  useUpdateGroupeMutation,
  useAddGroupeMutation,
  useArchiveGroupeMutation,
} = groupesApi;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { lazy } from "react";

export const HomeAdministrateurPage = lazy(
  () => import("features/administrateur")
);
export const UtilisateursPage = lazy(
  () => import("features/administrateur/utilisateurs/UtilisateursPage")
);
export const EditUtilisateurPage = lazy(
  () => import("features/administrateur/utilisateurs/EditUtilisateurPage")
);
export const CreateUtilisateurPage = lazy(
  () => import("features/administrateur/utilisateurs/CreateUtilisateurPage")
);
export const ProfilsPage = lazy(
  () => import("features/administrateur/profils/ProfilsPage")
);
export const ContratsPage = lazy(
  () => import("features/administrateur/contrats/ContratsPage")
);
export const CategoriesPage = lazy(
  () => import("features/administrateur/categories/CategoriesPage")
);
export const PopulationsPage = lazy(
  () => import("features/administrateur/populations/PopulationsPage")
);
export const EditPopulationPage = lazy(
  () => import("features/administrateur/populations/EditPopulationPage")
);
export const ServicesPage = lazy(
  () => import("features/administrateur/services/ServicesPage")
);
export const CompetencesPage = lazy(
  () => import("features/administrateur/competences/CompetencesPage")
);
export const GroupesPage = lazy(
  () => import("features/administrateur/groupes/GroupesPage")
);
export const GeneralPage = lazy(
  () => import("features/administrateur/general/GeneralPage")
);
export const EvenementsPage = lazy(
  () => import("features/administrateur/evenements/EvenementsPage")
);
export const JourneesTypesPage = lazy(
  () => import("features/administrateur/journeestypes/JourneesTypesPage")
);
export const EditJourneeTypePage = lazy(
  () => import("features/administrateur/journeestypes/EditJourneeTypePage")
);
export const SemainesTypesPage = lazy(
  () => import("features/administrateur/semainestypes/SemainesTypesPage")
);
export const EditSemaineTypePage = lazy(
  () => import("features/administrateur/semainestypes/EditSemaineTypePage")
);
export const RotationsTypesPage = lazy(
  () => import("features/administrateur/rotationstypes/RotationsTypesPage")
);
export const EditRotationTypePage = lazy(
  () => import("features/administrateur/rotationstypes/EditRotationTypePage")
);
export const CompteursPage = lazy(
  () => import("features/administrateur/compteurs/CompteursPage")
);
export const EditCompteurPage = lazy(
  () => import("features/administrateur/compteurs/EditCompteurPage")
);
export const ModelesMajorationPage = lazy(
  () =>
    import("features/administrateur/modelesmajoration/ModelesMajorationPage")
);
export const PrestationsPage = lazy(
  () => import("features/administrateur/prestations/PrestationsPage")
);
export const EditPrestationPage = lazy(
  () => import("features/administrateur/prestations/EditPrestationPage")
);
export const TraductionsPage = lazy(
  () => import("features/administrateur/traductions/TraductionsPage")
);

import { combineReducers } from "redux";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { profilsApi } from "services/profils";
import { categoriesApi } from "services/categories";
import { contratsApi } from "services/contrats";
import { traductionsApi } from "services/traductions";
import { utilisateursApi } from "services/utilisateurs";
import { servicesApi } from "services/services";
import { historiquesApi } from "services/historiques";
import { populationsApi } from "services/populations";
import { competencesApi } from "services/competences";
import { groupesApi } from "services/groupes";
import { evenementsApi } from "services/evenements";
import { journeesTypesApi } from "services/journeestypes";
import { semainesTypesApi } from "services/semainestypes";
import { rotationsTypesApi } from "services/rotationstypes";
import { compteursApi } from "services/compteurs";
import { modelesMajorationsApi } from "services/modelesmajorations";
import { parametragesApi } from "services/parametrages";
import { prestationsApi } from "services/prestations";
import { permissionsApi } from "services/permissions";
import { utilisateurVisibilitesApi } from "services/utilisateurvisibilites";
import { etiquettesApi } from "services/etiquettes";
import authReducer from "features/auth/authSlice";
import themeReducer from "assets/themes/themeSlice";
import dashboardReducer from "features/dashboard/dashboardSlice";

const combinedReducerUI = combineReducers({
  theme: themeReducer,
  dashboard: dashboardReducer,
});

const combinedReducer = combineReducers({
  [utilisateursApi.reducerPath]: utilisateursApi.reducer,
  [profilsApi.reducerPath]: profilsApi.reducer,
  [traductionsApi.reducerPath]: traductionsApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [contratsApi.reducerPath]: contratsApi.reducer,
  [servicesApi.reducerPath]: servicesApi.reducer,
  [historiquesApi.reducerPath]: historiquesApi.reducer,
  [populationsApi.reducerPath]: populationsApi.reducer,
  [competencesApi.reducerPath]: competencesApi.reducer,
  [groupesApi.reducerPath]: groupesApi.reducer,
  [evenementsApi.reducerPath]: evenementsApi.reducer,
  [journeesTypesApi.reducerPath]: journeesTypesApi.reducer,
  [semainesTypesApi.reducerPath]: semainesTypesApi.reducer,
  [rotationsTypesApi.reducerPath]: rotationsTypesApi.reducer,
  [compteursApi.reducerPath]: compteursApi.reducer,
  [modelesMajorationsApi.reducerPath]: modelesMajorationsApi.reducer,
  [parametragesApi.reducerPath]: parametragesApi.reducer,
  [prestationsApi.reducerPath]: prestationsApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  [utilisateurVisibilitesApi.reducerPath]: utilisateurVisibilitesApi.reducer,
  [etiquettesApi.reducerPath]: etiquettesApi.reducer,
  auth: authReducer,
  ui: combinedReducerUI,
});

const rootReducer = (state: any, action: any) => {
  //console.log(state?.contrats?.queries[Object.keys(state?.contrats?.queries)[0]]);
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      utilisateursApi.middleware,
      profilsApi.middleware,
      categoriesApi.middleware,
      contratsApi.middleware,
      servicesApi.middleware,
      historiquesApi.middleware,
      traductionsApi.middleware,
      populationsApi.middleware,
      competencesApi.middleware,
      groupesApi.middleware,
      evenementsApi.middleware,
      journeesTypesApi.middleware,
      semainesTypesApi.middleware,
      rotationsTypesApi.middleware,
      compteursApi.middleware,
      modelesMajorationsApi.middleware,
      parametragesApi.middleware,
      prestationsApi.middleware,
      permissionsApi.middleware,
      utilisateurVisibilitesApi.middleware,
      etiquettesApi.middleware
    ),
});

// configure listeners using the provided defaults
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";
import { IUtilisateur } from "./utilisateurs";

export interface IService {
  idService: number;
  uuid: string;
  nom: string;
  code: string;
  codeStandardiser: string;
  niveau: number;
  classement: number;
  sortPath: string;
  idParent: number | null;
  utilisateurs: IUtilisateur[];
  children?: ServiceResponse;
}

export interface IServiceTree extends IService {
  children: IServiceTree[];
}

type ServiceResponse = IService[];

export const servicesApi = createApi({
  reducerPath: "services",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Services"],
  endpoints: (build) => ({
    getServices: build.query<ServiceResponse, void>({
      query: () => ({ url: "/services" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }: { uuid: string }) =>
                  ({ type: "Services", uuid } as const)
              ),
              { type: "Services", id: "LIST" },
            ]
          : [{ type: "Services", id: "LIST" }],
    }),
    getTreeServices: build.query<IServiceTree[], void>({
      query: () => ({ url: "/services?tree=true" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }: { uuid: string }) =>
                  ({ type: "Services", uuid } as const)
              ),
              { type: "Services", id: "LIST" },
            ]
          : [{ type: "Services", id: "LIST" }],
    }),
    getService: build.query<IService, string>({
      query: (uuid) => ({ url: "/services/" + uuid }),
      providesTags: (result, error, uuid) => [{ type: "Services", uuid }],
    }),
    getOrganigramme: build.query<ServiceResponse, string>({
      query: (utilisateur) => ({
        url: "services/organigrammes/" + utilisateur,
      }),
      providesTags: (result, error, utilisateur) => [
        { type: "Services", utilisateur },
      ],
    }),
    getUtilisateursByUuidService: build.query<IUtilisateur[], string>({
      query: (uuid) => ({ url: "services/" + uuid + "/utilisateurs/" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "Services", uuid } as const)
              ),
              { type: "Services", id: "LIST" },
            ]
          : [{ type: "Services", id: "LIST" }],
    }),
    updateService: build.mutation<IService, Partial<IService>>({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/services/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "Services", uuid },
      ],
    }),
    addService: build.mutation<IService, Partial<IService>>({
      query: (body) => ({
        url: "/services",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "Services", id: "LIST" }],
    }),
    deleteService: build.mutation<{ success: boolean; uuid: any }, string>({
      query(uuid) {
        return {
          url: "/services/" + uuid,
          method: "delete",
        };
      },
      invalidatesTags: (result, error, uuid) => [{ type: "Services", uuid }],
    }),
  }),
});

export const {
  useGetServiceQuery,
  useGetServicesQuery,
  useGetTreeServicesQuery,
  useGetOrganigrammeQuery,
  useGetUtilisateursByUuidServiceQuery,
  useAddServiceMutation,
  useDeleteServiceMutation,
  useUpdateServiceMutation,
} = servicesApi;

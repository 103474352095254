/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth } from "hooks/useAuth";
import { isRouteAuthorized } from "helpers";
import { useRoleTheme } from "hooks/useRoleTheme";

function Role() {
  const { t } = useTranslation();
  const auth = useAuth();
  const roleTheme = useRoleTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [label, setLabel] = useState(t("utilisateur"));

  useEffect(() => {
    roleTheme === "UTILISATEUR" && setLabel(t("utilisateur"));
    roleTheme === "MANAGER" && setLabel(t("manager"));
    roleTheme === "ADMINISTRATEUR" && setLabel(t("administrateur"));
  }, [t, roleTheme]);

  return (
    <div>
      <Button
        aria-controls="role-menu"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        <>{label}</>
      </Button>
      <Menu
        id="role-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isRouteAuthorized(auth.session?.roles, ["ROLE_UTILISATEUR"]) && (
          <MenuItem component={Link} to="u/dashboard" onClick={handleClose}>
            {t("Utilisateur")}
          </MenuItem>
        )}
        {isRouteAuthorized(auth.session?.roles, ["ROLE_MANAGER"]) && (
          <MenuItem component={Link} to="m/dashboard" onClick={handleClose}>
            {t("Manager")}
          </MenuItem>
        )}
        {isRouteAuthorized(auth.session?.roles, ["ROLE_ADMINISTRATEUR"]) && (
          <MenuItem component={Link} to="a/dashboard" onClick={handleClose}>
            {t("Administrateur")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default Role;

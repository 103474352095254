/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { memo } from "react";
import StyledToolbar from "./StyledToolbar";
import Drawer from "@mui/material/Drawer";
import { useTheme, useMediaQuery } from "@mui/material";
import DrawerMenu from "./DrawerMenu";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "assets/icons/Menu";
import Box from "@mui/material/Box";

import Profil from "./Profil";
import { useDashboard } from "hooks/useDashboard";
import { useAppDispatch } from "store";
import { closeDrawer, toggleMenu } from "./dashboardSlice";

export const DRAWER_WIDTH = 240;
export const MINI_DRAWER_WIDTH = 80;
const DRAWER_TRANSITION = "width 0.3s ease-out";

const MemoizedDrawerMenu = memo(DrawerMenu);

export default function DashboardDrawer() {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const dashboard = useDashboard();
  const dispatch = useAppDispatch();

  return (
    <Drawer
      variant={desktop ? "permanent" : "temporary"}
      sx={{
        width: desktop
          ? dashboard.openMenu
            ? DRAWER_WIDTH
            : MINI_DRAWER_WIDTH
          : DRAWER_WIDTH,
        flexShrink: 0,
        transition: DRAWER_TRANSITION,
        [`& .MuiDrawer-paper`]: {
          width: desktop
            ? dashboard.openMenu
              ? DRAWER_WIDTH
              : MINI_DRAWER_WIDTH
            : DRAWER_WIDTH,
          boxSizing: "border-box",
          transition: DRAWER_TRANSITION,
          backgroundColor: "primary.main",
          color: "white",
          overflowX: "hidden",
          border: "none",
        },
      }}
      anchor="left"
      open={dashboard.openDrawer}
      onClose={() => dispatch(closeDrawer())}
    >
      <StyledToolbar />
      <Box
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
          width: DRAWER_WIDTH,
          boxShadow: "inset 0 5px 0px 0px rgb(255 255 255 / 60%)",
        }}
      >
        <Box
          sx={{
            textAlign: "right",
            padding: 1,
            width: desktop
              ? dashboard.openMenu
                ? DRAWER_WIDTH
                : MINI_DRAWER_WIDTH
              : DRAWER_WIDTH,
            transition: DRAWER_TRANSITION,
            display: { xs: "none", md: "block" },
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => dispatch(toggleMenu())}
            size="large"
          >
            <MenuIcon
              sx={{
                transform: dashboard.openMenu
                  ? "rotateY(180deg)"
                  : "rotateY(0deg)",
                transition: "transform 0.3s ease-out",
              }}
            />
          </IconButton>
        </Box>
        <Profil />
        <Box
          sx={{ overflowX: "hidden", overflowY: "auto", width: DRAWER_WIDTH }}
        >
          <MemoizedDrawerMenu />
        </Box>
      </Box>
    </Drawer>
  );
}

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { Navigate } from "react-router-dom";
import PrivateRoute from "routes/PrivateRoute";
import { HomeUtilisateurPage, ComptePage } from "./lazy/utilisateur";

export default function Utilisateur() {
  const routes = [
    {
      path: "dashboard",
      element: (
        <PrivateRoute roles={["ROLE_UTILISATEUR"]}>
          <HomeUtilisateurPage />
        </PrivateRoute>
      ),
    },
    {
      path: "compte",
      element: (
        <PrivateRoute roles={["ROLE_UTILISATEUR"]}>
          <ComptePage />
        </PrivateRoute>
      ),
    },
    {
      path: "*",
      element: <Navigate to="dashboard" />,
    },
  ];
  return { routes };
}

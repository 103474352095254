/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import React, { useEffect, useCallback, PropsWithChildren } from "react";
import { axios } from "../constants/API";
import { SnackbarKey, useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useAuth } from "hooks/useAuth";
import { useRefreshTokenMutation } from "services/auth";
import { AxiosError } from "axios";
import { useAppDispatch } from "store";
import { logout, setCredentials } from "features/auth/authSlice";

export const AuthContext = React.createContext({});

export default function AuthProvider({ children }: PropsWithChildren) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const auth = useAuth();
  const [refreshToken] = useRefreshTokenMutation();
  const dispatch = useAppDispatch();

  const displayError = useCallback(
    (error: AxiosError) => {
      const action = (key: SnackbarKey) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      );

      if (error.response === undefined) {
        // network error
        enqueueSnackbar("API ERROR : " + error.toString(), {
          variant: "error",
          action,
        });
      } else {
        let multipleErrors = !!error.response.data.errors;
        if (multipleErrors) {
          // multiple error Symfony
          let children = Object.values(error.response.data.errors.children) as {
            errors: string[];
          }[];
          children.forEach((child) => {
            if (child.errors) {
              child.errors.forEach((error) => {
                enqueueSnackbar(error, {
                  variant: "error",
                  action,
                });
              });
            }
          });
        } else {
          let errorMessage =
            error.config.method + " " + error.config.url + " => ";
          if (error.response.data.error) {
            // error with code
            errorMessage +=
              "ERROR " +
              error.response.data.error.code +
              " : " +
              error.response.data.error.message;
          } else {
            // simple error message
            errorMessage += "ERROR " + error.response.data.message;
          }
          enqueueSnackbar("API ERROR : " + errorMessage, {
            variant: "error",
            action,
          });
        }
      }
    },
    [closeSnackbar, enqueueSnackbar]
  );

  useEffect(() => {
    async function login() {
      try {
        const user = await refreshToken().unwrap();
        dispatch(setCredentials(user));
      } catch (err) {
        console.log(err);
      }
    }

    const tokenExpired = auth.session.exp;
    const isTokenExpired = !auth.isAuthenticated;
    if (tokenExpired && tokenExpired !== 0 && isTokenExpired) {
      login();
    }
  }, [auth.isAuthenticated, auth.session.exp, dispatch, refreshToken]);

  useEffect(() => {
    const postRefreshToken =
      (request = null) =>
      async () => {
        try {
          const user = await refreshToken().unwrap();
          dispatch(setCredentials(user));
          if (request) {
            return axios(request);
          }
        } catch (error) {
          dispatch(logout());
        }
      };

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        if (
          err.config.url !== "/login" &&
          err.config.url !== "/refresh-token"
        ) {
          return new Promise((resolve, reject) => {
            const originalReq = err.config;
            if (
              err.response.status === 401 &&
              err.config &&
              !err.config.__isRetryRequest
            ) {
              originalReq._retry = true;
              let res = postRefreshToken(originalReq);
              resolve(res);
            }
            reject(err);
          });
        } else {
          displayError(err);
          return Promise.reject(err);
        }
      }
    );
    return () => closeSnackbar();
  }, [closeSnackbar, dispatch, displayError, refreshToken]);

  return <AuthContext.Provider value="">{children}</AuthContext.Provider>;
}

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import MessageIcon from "assets/icons/Message";
import StyledBadge from "features/common/mui/StyledBadge";

export default function Message() {
  const { t } = useTranslation();
  return (
    <IconButton aria-label={t("Messages reçus")} color="inherit" size="large">
      <StyledBadge badgeContent={4}>
        <MessageIcon />
      </StyledBadge>
    </IconButton>
  );
}

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useRoleTheme } from "hooks/useRoleTheme";
import AdministrateurMenu from "./AdministrateurMenu";
import ManagerMenu from "./ManagerMenu";
import UtilisateurMenu from "./UtilisateurMenu";

function DrawerMenu() {
  const roleTheme = useRoleTheme();

  const ListDrawer = () => {
    switch (roleTheme) {
      case "ADMINISTRATEUR":
        return <AdministrateurMenu />;
      case "MANAGER":
        return <ManagerMenu />;
      default:
        return <UtilisateurMenu />;
    }
  };

  return <ListDrawer />;
}

export default DrawerMenu;

/**
 * Ce fichier fait parti du projet e2Time.com - Administration React
 *
 * (c) e2Time.com <support@e2time.com
 *
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 */
import { createInstance } from "@datapunt/matomo-tracker-react";

export const matomo = createInstance({
  urlBase: process.env.REACT_APP_MATOMO_URL + window.location.href,
  siteId: process.env.REACT_APP_MATOMO_ID_SITE,
  trackerUrl: process.env.REACT_APP_MATOMO_URL + "matomo.php", // optional, default value: `${urlBase}matomo.php`
  srcUrl: process.env.REACT_APP_MATOMO_URL + "matomo.js", // optional, default value: `${urlBase}matomo.js`
});

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IRotationType {
  idRotationType: number;
  uuid: string;
  nom: string;
  utilisateurUuid: string | null;
  dateCreation: string | null;
  dateModification: string;
  nbSemaine: number;
  planningSurFerie: boolean;
  archive: boolean;
  externalId: number;
}

export interface IRotationSemaineType {
  uuid?: string;
  idSemaineType?: number;
  uuidSemaineType: string;
  libelle?: string;
  position: number;
}

type RotationSemainesTypesResponse = IRotationSemaineType[];

export interface IRotationTypeForm {
  uuid: string;
  utilisateurUuid: string;
  nom: string;
  planningSurFerie: boolean;
  archive: boolean;
  semaineTypes: IRotationSemaineType[];
}

type RotationsTypesResponse = IRotationType[];

export const rotationsTypesApi = createApi({
  reducerPath: "rotationstypes",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["RotationsTypes"],
  endpoints: (build) => ({
    getRotationsTypes: build.query<RotationsTypesResponse, void>({
      query: () => ({ url: "/rotations-types" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ uuid }) => ({ type: "RotationsTypes", uuid } as const)
              ),
              { type: "RotationsTypes", id: "LIST" },
            ]
          : [{ type: "RotationsTypes", id: "LIST" }],
    }),
    getRotationType: build.query<IRotationType, string>({
      query: (uuid) => ({ url: "/rotations-types/" + uuid }),
      providesTags: (result, error, uuid) => [{ type: "RotationsTypes", uuid }],
    }),
    getRotationSemaineType: build.query<RotationSemainesTypesResponse, string>({
      query: (uuid) => ({
        url: "/rotations-types/" + uuid + "/semaines-types",
      }),
      providesTags: (result, error, uuid) => [{ type: "RotationsTypes", uuid }],
    }),
    updateRotationType: build.mutation<
      IRotationTypeForm,
      Partial<IRotationTypeForm>
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/rotations-types/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: (result, error, { uuid }) => [
        { type: "RotationsTypes", uuid },
      ],
    }),
    addRotationType: build.mutation<
      IRotationTypeForm,
      Partial<IRotationTypeForm>
    >({
      query: (body) => ({
        url: "/rotations-types",
        method: "post",
        data: body,
      }),
      invalidatesTags: [{ type: "RotationsTypes", id: "LIST" }],
    }),
    archiveRotationType: build.mutation<IRotationType, string | number>({
      query(uuid) {
        return {
          url: "/rotations-types/" + uuid + "/archives",
          method: "patch",
        };
      },
      invalidatesTags: (result, error, uuid) => [
        { type: "RotationsTypes", uuid },
      ],
    }),
  }),
});

export const {
  useGetRotationsTypesQuery,
  useGetRotationTypeQuery,
  useGetRotationSemaineTypeQuery,
  useUpdateRotationTypeMutation,
  useAddRotationTypeMutation,
  useArchiveRotationTypeMutation,
} = rotationsTypesApi;

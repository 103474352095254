import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { AxiosRequestConfig, AxiosError } from "axios";
import { axios } from "constants/API";

const axiosBaseQuery =
  (
    prefix: string = ""
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      baseURL?: AxiosRequestConfig["baseURL"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        baseURL: process.env.REACT_APP_BASE_URL + prefix,
        url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

export const baseQuery = axiosBaseQuery();
export const baseQueryUtilisateur = axiosBaseQuery("u/");
export const baseQueryManager = axiosBaseQuery("m/");
export const baseQueryAdministrateur = axiosBaseQuery("a/");

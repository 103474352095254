import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryAdministrateur } from ".";

export interface IPermissionChild {
  name: string;
  uuid: string;
  children?: IPermissionChild[];
}

export interface IPermission {
  [x: string]: any;
  Utililisateur: IPermissionChild;
  Manager: IPermissionChild;
  Administrateur: IPermissionChild;
}

export const permissionsApi = createApi({
  reducerPath: "permissions",
  baseQuery: baseQueryAdministrateur,
  tagTypes: ["Permissions"],
  endpoints: (build) => ({
    getPermissionsGeneral: build.query<IPermission, void>({
      query: () => ({ url: "/permissions/pages/general" }),
      providesTags: ["Permissions"],
    }),
    getPermissionsUtilisateur: build.query<IPermission, string>({
      query: (uuid) => ({ url: "/permissions/pages/utilisateur?uuid=" + uuid }),
      providesTags: ["Permissions"],
    }),
    updatePermissionsGeneral: build.mutation<
      IPermission,
      { permissions: string[] }
    >({
      query(data) {
        return {
          url: "/permissions/general",
          method: "patch",
          data: data,
        };
      },
      invalidatesTags: ["Permissions"],
    }),
    updatePermissionsUtilisateur: build.mutation<
      IPermission,
      { uuid: string; permissions: string[] }
    >({
      query(data) {
        const { uuid, ...body } = data;
        return {
          url: "/permissions/utilisateurs/" + uuid,
          method: "patch",
          data: body,
        };
      },
      invalidatesTags: ["Permissions"],
    }),
  }),
});

export const {
  useGetPermissionsGeneralQuery,
  useGetPermissionsUtilisateurQuery,
  useUpdatePermissionsGeneralMutation,
  useUpdatePermissionsUtilisateurMutation,
} = permissionsApi;

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ParametrageGeneral(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <path
        d="M11.051,24.39l-0.709-0.705l0.069-0.073c0.216-0.233,0.578-0.623,1.381-0.623h8.06
				c0.502-0.171,3.342-2.922,4.008-3.667c0.349-0.392,1.883-2.691,2.504-3.932c-0.301-0.191-0.833-0.326-1.471-0.178
				c-0.725,0.169-1.211,0.675-1.858,1.933c-0.047,0.092-0.123,0.167-0.214,0.215l-2.511,1.304l-0.461-0.888l2.371-1.231
				c0.646-1.227,1.297-2.039,2.448-2.307c1.264-0.294,2.289,0.176,2.705,0.737c0.106,0.143,0.128,0.331,0.059,0.494
				c-0.531,1.243-2.395,4.036-2.825,4.518c-0.036,0.041-3.774,4.001-4.754,4.001h-8.06c-0.367,0-0.479,0.121-0.648,0.303
				L11.051,24.39z"
      />
      <path
        d="M18.756,20.702h-4.929v-1h4.929c0.79,0,1.143-0.436,1.143-0.869c0-0.432-0.353-0.869-1.143-0.869H15.47
				c-0.372,0-0.708-0.258-1.098-0.557c-0.297-0.227-0.703-0.539-0.937-0.539h-2.738c-0.537,0-1.337,0.484-1.85,0.961
				c-0.811,0.813-2.266,2.188-2.266,2.188l-0.687-0.727c0.001-0.001,1.442-1.363,2.258-2.181c0.622-0.58,1.65-1.242,2.544-1.242
				h2.738c0.573,0,1.089,0.396,1.545,0.745c0.17,0.131,0.429,0.329,0.523,0.354l3.252-0.003c1.407,0,2.143,0.94,2.143,1.869
				S20.163,20.702,18.756,20.702z"
      />
      <path
        d="M9.054,27.274c-0.128,0-0.256-0.049-0.354-0.146l-6.024-6.024c-0.195-0.195-0.195-0.512,0-0.707
				l2.19-2.19c0.195-0.195,0.512-0.195,0.707,0l6.024,6.024c0.195,0.195,0.195,0.512,0,0.707l-2.19,2.19
				C9.31,27.226,9.182,27.274,9.054,27.274z M3.736,20.75l5.317,5.317l1.483-1.483L5.22,19.267L3.736,20.75z"
      />
      <path
        d="M11.208,16.746l-1.095-1.897c-0.138-0.239-0.056-0.545,0.183-0.683L11.45,13.5
				c-0.046-0.153-0.088-0.309-0.124-0.465H9.993c-0.276,0-0.5-0.224-0.5-0.5v-2.19c0-0.276,0.224-0.5,0.5-0.5h1.333
				c0.036-0.156,0.078-0.312,0.125-0.465l-1.153-0.666c-0.115-0.066-0.199-0.175-0.233-0.304c-0.034-0.128-0.017-0.265,0.05-0.379
				l1.095-1.896c0.138-0.239,0.444-0.322,0.683-0.183l1.148,0.663c0.111-0.119,0.225-0.233,0.343-0.343l-0.664-1.148
				c-0.066-0.115-0.084-0.251-0.05-0.379s0.118-0.237,0.233-0.304L14.8,3.344c0.115-0.066,0.251-0.083,0.379-0.05
				c0.128,0.034,0.237,0.118,0.304,0.233l0.666,1.154c0.153-0.046,0.308-0.088,0.465-0.124V3.226c0-0.276,0.224-0.5,0.5-0.5h2.19
				c0.276,0,0.5,0.224,0.5,0.5v1.332c0.156,0.036,0.312,0.078,0.465,0.124l0.666-1.154C21,3.413,21.109,3.329,21.238,3.294
				c0.127-0.034,0.265-0.016,0.379,0.05l1.897,1.096c0.115,0.066,0.199,0.176,0.233,0.304s0.017,0.265-0.05,0.379l-0.664,1.148
				c0.118,0.11,0.232,0.225,0.342,0.343l1.148-0.663c0.238-0.139,0.544-0.057,0.683,0.183l1.096,1.896
				c0.066,0.115,0.084,0.251,0.05,0.379c-0.034,0.128-0.118,0.237-0.233,0.304L24.967,9.38c0.047,0.154,0.088,0.309,0.124,0.465
				h1.332c0.276,0,0.5,0.224,0.5,0.5v2.19c0,0.276-0.224,0.5-0.5,0.5h-1.331c-0.037,0.158-0.079,0.314-0.126,0.47l1.336,0.794
				l-0.512,0.859l-1.683-1.001c-0.207-0.123-0.297-0.377-0.213-0.603c0.132-0.358,0.231-0.729,0.294-1.103
				c0.041-0.241,0.249-0.417,0.493-0.417h1.241v-1.19h-1.241c-0.244,0-0.453-0.176-0.493-0.417
				c-0.063-0.374-0.162-0.745-0.294-1.103c-0.084-0.229,0.008-0.484,0.219-0.606l1.074-0.62l-0.596-1.031l-1.07,0.618
				c-0.212,0.121-0.48,0.073-0.636-0.115c-0.242-0.293-0.514-0.565-0.808-0.809c-0.188-0.156-0.236-0.424-0.114-0.635l0.618-1.07
				L21.55,4.46l-0.62,1.076c-0.122,0.21-0.379,0.304-0.607,0.219c-0.356-0.133-0.727-0.232-1.102-0.295
				c-0.241-0.041-0.417-0.249-0.417-0.493V3.726h-1.19v1.241c0,0.244-0.176,0.453-0.417,0.493c-0.377,0.063-0.748,0.163-1.102,0.294
				c-0.229,0.085-0.486-0.007-0.608-0.218l-0.62-1.076l-1.031,0.596l0.618,1.07c0.122,0.211,0.074,0.48-0.115,0.636
				C14.05,7,13.778,7.272,13.529,7.572c-0.155,0.187-0.423,0.234-0.634,0.113l-1.07-0.617l-0.595,1.03l1.075,0.62
				c0.211,0.122,0.304,0.379,0.219,0.607c-0.133,0.356-0.232,0.727-0.295,1.102c-0.041,0.241-0.249,0.417-0.493,0.417h-1.242v1.19
				h1.242c0.244,0,0.453,0.176,0.493,0.417c0.063,0.375,0.162,0.746,0.295,1.102c0.085,0.229-0.007,0.485-0.219,0.607l-1.075,0.62
				l0.845,1.464L11.208,16.746z"
      />
      <path
        d="M18.209,14.131c-1.484,0-2.691-1.207-2.691-2.69s1.208-2.69,2.691-2.69c1.483,0,2.69,1.207,2.69,2.69
				S19.692,14.131,18.209,14.131z M18.209,9.75c-0.933,0-1.691,0.758-1.691,1.69s0.759,1.69,1.691,1.69
				c0.932,0,1.69-0.758,1.69-1.69S19.141,9.75,18.209,9.75z"
      />
    </SvgIcon>
  );
}

/**
 * Ce fichier fait parti du projet e2Time.com - Administration React
 *
 * (c) e2Time.com <support@e2time.com
 *
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 */
import Axios from "axios";

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
  crossdomain: true,
  withCredentials: "include",
  headers: {
    Accept: "application/json",
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    "X-e2time-Api-Key": process.env.REACT_APP_E2TIME_API_KEY,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type, X-e2time-Api-Key,",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Access-Control-Allow-Credentials": true,
  },
});

axios.CancelToken = Axios.CancelToken;
axios.isCancel = Axios.isCancel;

axios.defaults.withCredentials = "include";

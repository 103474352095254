/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import Yup from "constants/Yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Stack,
  Box,
  Button,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";

import { useLoginMutation, ILoginForm } from "services/auth";
import { useAppDispatch } from "store";
import { setCredentials } from "./authSlice";

/**
 * @see https://github.com/jquense/yup
 */
const validationSchema = Yup.object({
  username: Yup.string().required(),
  password: Yup.string().required(),
  societe: Yup.string().required(),
});

/**
 * Formulaire d'authentification
 */
function LoginForm() {
  // Initialisation de la traduction
  const { t } = useTranslation();
  const [login] = useLoginMutation();
  let { societe } = useParams();
  const dispatch = useAppDispatch();

  /**
   * @see https://react-hook-form.com/api/useform
   */
  const defaultValues = {
    username: "",
    password: "",
    societe: "societe_" + societe,
  };

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<ILoginForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: ILoginForm) => {
    try {
      const user = await login(data).unwrap();
      dispatch(setCredentials(user));
    } catch ({ data }) {
      const { children: errors } = data.errors;
      Object.entries(errors).forEach(([name, { errors }]: any[]) => {
        errors &&
          setError(name, {
            message: errors[0],
          });
      });
    }
  };

  const TextField = ({ ...props }) => (
    <Controller
      render={({ field }) => <MuiTextField {...props} {...field} />}
      name={props.name}
      control={control}
    />
  );

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        id="username"
        name="username"
        variant="outlined"
        label={t("Identifiant")}
        error={!!errors.username}
        helperText={!!errors.username && errors.username.message}
      />
      <TextField
        fullWidth
        id="password"
        name="password"
        type="password"
        variant="outlined"
        label={t("Mot de passe")}
        error={!!errors.password}
        helperText={!!errors.password && errors.password.message}
      />{" "}
      <Box
        component={Link}
        to="/request-password"
        sx={{
          textDecoration: "none",
          textAlign: "right",
          color: "primary.main",
        }}
      >
        <Typography variant="body2">{t("Mot de passe oublié ?")}</Typography>
      </Box>
      <Box>
        <Button color="primary" variant="contained" type="submit">
          {t("Se connecter")}
        </Button>
      </Box>
    </Stack>
  );
}

export default LoginForm;

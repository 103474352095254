/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import RequestPasswordForm from "./RequestPasswordForm";

export default function RequestPasswordPage() {
  // Initialisation de la traduction
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="h5"
        component="h1"
        sx={{ mt: 6, color: "primary.main" }}
      >
        {t("Mot de passe perdu ?")}
      </Typography>
      <Typography variant="body1" component="p" sx={{ my: 6 }}>
        {t(
          "Nous allons vous envoyer par e-mail une demande de réinitialisation de mot de passe qui vous permettra d'accéder à votre Espace e2time."
        )}
      </Typography>
      <Typography variant="body1" component="p" sx={{ my: 6 }}>
        {t(
          "Pour le recevoir, il vous suffit de saisir votre identifiant, ou bien votre adresse e-mail"
        )}
      </Typography>
      <RequestPasswordForm />
    </>
  );
}

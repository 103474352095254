/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useState, useEffect, useCallback } from "react";
import {
  createTheme,
  ThemeOptions,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Dashboard from "./Dashboard";
import { utilisateur, manager, administrateur } from "assets/themes/Theme";
import { useRoleTheme } from "hooks/useRoleTheme";

export default function DashboardPage() {
  const theme = useTheme();
  const roleTheme = useRoleTheme();

  const [themeRole, setThemeRole] = useState(utilisateur);

  useEffect(() => {
    roleTheme === "UTILISATEUR" && setThemeRole(utilisateur);
    roleTheme === "MANAGER" && setThemeRole(manager);
    roleTheme === "ADMINISTRATEUR" && setThemeRole(administrateur);
  }, [roleTheme]);

  const newTheme = useCallback(
    (theme: ThemeOptions | undefined) =>
      createTheme({
        ...theme,
        ...themeRole,
      }),
    [themeRole]
  );
  return (
    <ThemeProvider theme={newTheme(theme)}>
      <CssBaseline />
      <Dashboard />
    </ThemeProvider>
  );
}

/**
 * L'utilisation ce fichier est soumis à l'autorisation expresse de la
 * société e2Time.com. Toute utilisation sans l'accord d'e2Time.com
 * fera l'objet de poursuites.
 *
 * @file Ce fichier fait parti du projet e2Time.com - Frontend e2Time
 * @copyright e2Time.com <support@e2time.com>
 */
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import Yup from "constants/Yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Stack, Box, Button, Typography } from "@mui/material";

import { useResetPasswordMutation, IResetPasswordForm } from "services/auth";
import { TextField } from "features/common/reacthookform/Controller";

/**
 * @see https://github.com/jquense/yup
 */
const validationSchema = Yup.object({
  password: Yup.string().required(),
  confirmation: Yup.string().required(),
  societe: Yup.string().required(),
  token: Yup.string().required(),
});

/**
 * Formulaire d'authentification
 */
function ResetPasswordForm() {
  // Initialisation de la traduction
  const { t } = useTranslation();

  let navigate = useNavigate();

  let { token, societe } = useParams();

  const [resetPassword] = useResetPasswordMutation();
  /**
   * @see https://react-hook-form.com/api/useform
   */
  const defaultValues = {
    password: "",
    confirmation: "",
    societe: "societe_" + societe,
    token,
  };

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<IResetPasswordForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    criteriaMode: "all",
  });

  const onSubmit = (data: IResetPasswordForm) => {
    resetPassword(data)
      .unwrap()
      .then(() => navigate("/"))
      .catch(({ data }) => {
        const { children: errors } = data.errors;
        Object.entries(errors).forEach(([name, { errors }]: any[]) => {
          errors &&
            setError(name, {
              message: errors,
            });
        });
      });
  };

  return (
    <Stack spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        id="password"
        name="password"
        type="password"
        label={t("Nouveau mot de passe")}
        error={!!errors.password}
        helperText={!!errors.password && errors.password.message}
      />
      <TextField
        control={control}
        fullWidth
        id="confirmation"
        name="confirmation"
        type="password"
        label={t("Confirmation mot de passe")}
        error={!!errors.confirmation}
        helperText={!!errors.confirmation && errors.confirmation.message}
      />
      <Box
        component={Link}
        to="/"
        sx={{
          textDecoration: "none",
          textAlign: "right",
          color: "primary.main",
        }}
      >
        <Typography variant="body2">{t("> Retour à l'accueil")}</Typography>
      </Box>
      <Box>
        <Button color="primary" variant="contained" type="submit">
          {t("Réinitialiser mon mot de passe")}
        </Button>
      </Box>
    </Stack>
  );
}

export default ResetPasswordForm;
